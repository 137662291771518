
const defaultIntegrationConfig = {
        name: 'default',
        base: {
          debug: true,
          shop: {
            node: window,
            prefix: 'puma-',
            language: {
              path: 'fitAnalyticsData.shopLanguage'
            },
            country: {
              path: 'fitAnalyticsData.shopCountry'
            },
            shopSessionId: {
              path: 'fitAnalyticsData.shopSessionId'
            }
          },

          product: {
            scope: 'div.testCase.container',
            container: 'div.product',
            currentSerial: {
              node: window,
              path: 'fitAnalyticsData.currentItemSubgroupId'
            },
            thumbnail: {
              node: window,
              path: 'fitAnalyticsData.mainImageLink'
            },
            addToCart: (serial, size) => window.fitAnalyticsData.operations.addToCart(serial, size),
            sizes: {
              node: window,
              items: {
                path: 'fitAnalyticsData.sizes'
              },
              code: {
                path: 'value'
              },
              isAvailable: {
                path: 'isAvailable'
              },
              shouldSelectRecommended: false
            },
            hasManufacturedSizes: true
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: 'button.size-chart-button',
            shouldHide: false
          }
        }
      },


      integrationConfigs = [
        defaultIntegrationConfig
      ];

export { integrationConfigs, defaultIntegrationConfig };

import { Alert, Button, Collapse } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {launchIntegration, getCaseState} from "./CaseSlice";
import { getPageConfig } from "../PageConfigSlice";
import { startIntegration } from "../../../integration";
import { useNavigate } from "react-router";
import merge from "lodash.merge";
import { useIntegration } from "./useIntegration";


function ConfigPane({configs}) {
  const pageConfig = useSelector(getPageConfig),
        caseState = useSelector(getCaseState),
        navigate = useNavigate();

  const { triggerIntegration } = useIntegration();

  return (
    <Collapse in={!pageConfig.isDefaultIntegration && !caseState.isIntegrationLaunched}>
      <div>
        <Alert>
          <Alert.Heading>Choose an integration config</Alert.Heading>
          {
            configs.map((ic) => {
              return <Button
                className = 'mt-2 me-3'
                key={ic.name}
                name={ic.name}
                variant='secondary'
                onClick={()=> triggerIntegration(ic)}>{ic.name}</Button>;
            })
          }
          <div className='mt-3'>
            This pane is shown to you as the default integration config is switched off.
            Follow this <Alert.Link onClick={()=>navigate('/testing/page-config')}>link </Alert.Link>
            to switch it back on.
          </div>
        </Alert>
      </div>
    </Collapse>
  );
}

export default ConfigPane;

export const formatSizesToJSON = (sizes = [], availability) => {
  sizes = sizes.map((size, index, array) => {
    return {
      value: size,
      isAvailable: index/(array.length-1) <= availability
    };
  });
  return JSON.stringify(sizes);
};

export const FitFinder = ({externalId, styleId, colorId, sizes, thumbnail, delimiter='', sizeAvailability=1}) => {
  externalId ||= `${styleId}${delimiter}${colorId}`;
  return (
    <fit-finder
      external-id={externalId}
      thumbnail={thumbnail}
      sizes={formatSizesToJSON(sizes, sizeAvailability)}
    ></fit-finder>
  );
};

import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCaseState, setCountry, unsetCountry } from "./cases/CaseSlice";

function CountrySelect({countries=[]}) {
  const dispatch = useDispatch(),
        caseState = useSelector(getCaseState);

  useEffect(()=>{
    dispatch(setCountry(countries[0]));
    return () => {
      dispatch(unsetCountry());
    };
  }, []);

  const onCountryChange = function (e) {
    dispatch(setCountry(e.target.value));
  };

  return (
    <Form.Select
      size="sm"
      style={{width: 'fit-content'}}
      onChange={onCountryChange}
      value={caseState.country || 'US'}
    >
      <option key='default' disabled="disabled">Select Country</option>
      {
        countries.map((country) => {
          return <option
            key={country}
            value={country}
          >{country}</option>;
        })
      }
    </Form.Select>
  );
}

export {
  CountrySelect
};

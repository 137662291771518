import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import { getPageConfig } from "../PageConfigSlice";
import { useIntegration } from './useIntegration';
import AddToCartButton from "../AddToCartButton";
import SizeChart from "../SizeChart";
import Sizes from "../Sizes";
import Thumbnail from "../Thumbnail";
import Title from "../Title";
import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import Cart from "../Cart";
import Cookies from "js-cookie";
import { getCaseState } from "./CaseSlice";
import { getCartItems } from "../cartSlice";
import {integrationConfigs, defaultIntegrationConfig} from '../../../integration_configs/config11';

function Case11(props = {}) {
  const pageConfig = useSelector(getPageConfig),
        caseState = useSelector(getCaseState),
        cartItems = useSelector(getCartItems),
        [isAdoptionFlagEnabled, setFlagState] = useState(Cookies.get('msAlertDisabled') !== '1');

  useIntegration(defaultIntegrationConfig);
  useEffect(() => {
    window.fitAnalyticsData = {
      operations: {
        getCartItems: () => {
          return Promise.resolve(cartItems);
        }
      }
    };
  }, [cartItems]);
  const unsetCookie = () => {
    Cookies.set('msAlertDisabled', '', {path: '/'});
    setFlagState(true);
  };

  return (<>
    <ConfigPane configs={integrationConfigs}/>
    <Container className='testCase mt-4'>
      <Row className='add-to-cart'>
        <Col sm={8}></Col>
        <Col sm={4}>
          <Cart placement="end" name="end"></Cart>
        </Col>
      </Row>
      <Row className='mb-3' hidden={isAdoptionFlagEnabled}>
        <Col>
          <Button onClick={unsetCookie}>Unset the Adoption Flag cookie</Button>
          <p className='mt-2' hidden={!pageConfig.isDefaultIntegration && !caseState.isIntegrationLaunched}>
            After unsetting the cookie, manually reload the page.
          </p>
        </Col>
      </Row>
      <Row className = 'product'>
        <Thumbnail src = {props.thumbnail}/>
        <Col>
          <Meta {... props}/>
          <Title title={props.title}/>
          <p>{props.description}</p>
          <Sizes sizes={props.sizes}
            availability={4/5}
          />
          <SizeChart />
          <AddToCartButton
            productId={props.productId}
            thumbnail={props.thumbnail}
            showCartOnAdd={false}
          />
        </Col>
      </Row>
    </Container>
  </>);
}
export { Case11 };

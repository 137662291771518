import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import AddToCartButton from "../AddToCartButton";
import Sizes from "../Sizes";
import SizeChart from "../SizeChart";
import Thumbnail from "../Thumbnail";
import Title from "../Title";
import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import { setProduct } from './caseProductsSlice';
import { useIntegration } from "./useIntegration";
import { defaultIntegrationConfig, integrationConfigs } from "../../../integration_configs/config4";

const Case4 = (props={}) => {
  const dispatch = useDispatch(),
        productKey = props.productId;

  useIntegration(defaultIntegrationConfig);

  useEffect(() => {
    dispatch(setProduct({id: productKey, product: {}}));
  }, []);

  window.fitAnalyticsData = props || {};

  return (<>
    <ConfigPane configs={integrationConfigs}/>
    <Container className='testCase'>
      <Row className='product'>
        <Thumbnail src = {props.thumbnail}/>
        <Col>
          <Meta {...props}/>
          <Title title={props.title}/>
          <p>{props.description}</p>
          <Sizes sizes={props.sizes.map(size => size.value)}
          />
          <SizeChart />
          <AddToCartButton
            productId= {props.productId}
            thumbnail = {props.thumbnail}/>
        </Col>
      </Row>
    </Container>
  </>);
};

export { Case4 };

import { Card, Col, Row, CloseButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAsync,removeItemFromCart, removeAsyncItemFromCart } from "./cartSlice";

const CartItem = ({ cartItem }) => {
  const dispatch = useDispatch(),
        isAsync = useSelector(getAsync);

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col md={3}>
              <Card.Img src={cartItem.thumbnail}></Card.Img>
            </Col>
            <Col>
              <CloseButton onClick={() => {
                dispatch(isAsync ?
                  removeAsyncItemFromCart(cartItem) :
                  removeItemFromCart(cartItem)
                );
              }}/>
              <span><b>Serial: </b>{cartItem.productId}</span>
              <br/>
              <span><b>Size: </b>{cartItem.size}</span>
              <br/>
              <span><b>Quantity: </b>{cartItem.quantity}</span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CartItem;

import { Container, Row, Col } from "react-bootstrap";
import AddToCartButton from "../AddToCartButton";
import Sizes from "../Sizes";
import Thumbnail from "../Thumbnail";
import Title from "../Title";
import ConfigPane from "./ConfigPane";
import ListingPage from "../ListingPage";
import QuickView from "../Quickview";
import ProductTile from "../ProductTile";
import Meta from "../Meta";
import { Swatches } from "../Swatches";
import SizeChartButton from "../SizeChart";
import { integrationConfigs, defaultIntegrationConfig } from "../../../integration_configs/config6";
import { OutfitProduct } from "../OutfitProduct";
import { useIntegration } from "./useIntegration";

const Case6 = (props = {}) => {

  useIntegration(defaultIntegrationConfig);

  return (<>
    <ConfigPane configs={integrationConfigs} />
    <Container className='testCasePrimary'>
      <h2>Main Product</h2>
      <Row>
        <Thumbnail src = {props.thumbnail} />
        <Col>
          <Meta {... props} styleId={props.productPDP.styleId}/>
          <Title title={props.productPDP.title} />
          <p>{props.description}</p>
          <Swatches productKey={props.productPDP.styleId} colors={props.productPDP.colorIds} />
          <Sizes sizes={props.productPDP.sizes}
            availability={4/5}
          />
          <SizeChartButton />
          <AddToCartButton />
        </Col>
      </Row>
    </Container>
    <br/>
    <Container className='outfit'>
      <h2>Complete your look:</h2>
      {
        props.productsOutfit.map((product, index) => {
          return <OutfitProduct key={index} {... product} />;
        })
      }
    </Container>
    <br/>
    <Container className="plp">
      <h2>You may also be interested in:</h2>
      <ListingPage className='listingPage' Tile={ProductTile} items={props.productsQV}/>
      <QuickView style={{ overlay: {zIndex: 100} }} />
    </Container>
  </>);

};

export { Case6 };

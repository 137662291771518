const testCases = [
  {
    caseId: 'case1',
    thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50423170960.jpg',
    title: 'Basic Test',
    description: 'A basic PDP with a single colored product.',
    country: 'DE',
    language: 'de',
    userLanguage: 'en',
    userId: '123456789',
    productId: '50423170960',
    sizes: ['XS', 'S', 'M', 'L', 'XL'],
    styleId: '50423170'
  },
  {
    caseId: 'case2',
    thumbnail: 'https://images.fitanalytics.com/garments/defshop/300/366182.jpg',
    title: 'Experiments',
    description: 'A basic PDP with experiments.',
    country: 'DE',
    language: 'de',
    userLanguage: 'en',
    productId: '01T71Q3-YWO',
    sizes: ['XS', 'S', 'M', 'L', 'XL'],
    styleId: '123456789'
  },
  {
    thumbnail: 'https://images.puma.com/image/upload/f_auto%2Cq_auto%2Cb_rgb:fafafa%2Cw_300%2Ch_300/global/847070/02/fnd/EEA/fmt/png/Evostripe-Damen-T-Shirt',
    title: 'Single PDP - Object Based',
    description: 'A single color PDP with data object',
    productId: '847070_17',
    country: 'DE',
    language: 'en',
    userId: '123456789',
    shopSessionId: '_gid',
    sizes: [
      { "value": "XXS", "isAvailable": true },
      { "value": "XS", "isAvailable": true },
      { "value": "S", "isAvailable": true },
      { "value": "M", "isAvailable": true },
      { "value": "L", "isAvailable": false },
      { "value": "XL", "isAvailable": false }
    ],
    caseId: 'case3'
  },
  {
    caseId: 'case4',
    thumbnail: 'https://speedo.com.au/dw/image/v2/BDFS_PRD/on/demandware.static/-/Sites-speedo-master-catalog/default/dw3a80deac/images/1538A/1538A_8150_1.jpg?sw=600&sh=600',
    title: 'Single PDP - Dom & Object Based',
    description: 'PDP with data object - single colour only',
    productId: '1538A_8150',
    country: 'AU',
    language: 'en',
    shopSessionId: '',
    userId: '12345',
    sizes: [
      {
        value: "Small",
        isAvailable: true
      },
      {
        value: "Medium",
        isAvailable: false
      },
      {
        value: "Large",
        isAvailable: false
      },
      {
        value: "X-Large",
        isAvailable: true
      },
      {
        value: "XX-Large",
        isAvailable: true
      },
      {
        value: "XXX-Large",
        isAvailable: false
      }
    ]
  },
  {
    thumbnail: 'https://images.hugoboss.com/is/image/boss/hbeu50501843_427_350?$re_fullPageZoom$&qlt=85&fit=crop,1&align=1,1&lastModified=1708095424000&wid=1200&hei=1818',
    title: '2D sizes PDP + OBJECT',
    description: 'PDP with 2D sizes & OBJECT (client: boss)',
    productId: '50501843427',
    sizes: ["29/32", "29/34", "30/30", "30/32", "30/34", "31/30", "31/32", "31/34", "32/30", "32/32", "32/34", "32/36", "33/30", "33/32", "33/34", "33/36", "34/30", "34/32", "34/34", "34/36", "35/30", "35/32", "35/34", "35/36", "36/30", "36/32", "36/34", "36/36", "38/30", "38/32", "38/34", "38/36", "40/32", "40/34", "42/34"],
    objectSizes: [
      {value: "29/32", isAvailable: true},
      {value: "29/34", isAvailable: true},
      {value: "30/30", isAvailable: false},
      {value: "30/32", isAvailable: true},
      {value: "30/34", isAvailable: true},
      {value: "31/30", isAvailable: false},
      {value: "31/32", isAvailable: true},
      {value: "31/34", isAvailable: true},
      {value: "32/30", isAvailable: true},
      {value: "32/32", isAvailable: true},
      {value: "32/34", isAvailable: true},
      {value: "32/36", isAvailable: true},
      {value: "33/30", isAvailable: true},
      {value: "33/32", isAvailable: true},
      {value: "33/34", isAvailable: true},
      {value: "33/36", isAvailable: true},
      {value: "34/30", isAvailable: true},
      {value: "34/32", isAvailable: true},
      {value: "34/34", isAvailable: true},
      {value: "34/36", isAvailable: true},
      {value: "35/30", isAvailable: false},
      {value: "35/32", isAvailable: true},
      {value: "35/34", isAvailable: true},
      {value: "35/36", isAvailable: true},
      {value: "36/30", isAvailable: true},
      {value: "36/32", isAvailable: true},
      {value: "36/34", isAvailable: true},
      {value: "36/36", isAvailable: true},
      {value: "38/30", isAvailable: true},
      {value: "38/32", isAvailable: true},
      {value: "38/34", isAvailable: true},
      {value: "38/36", isAvailable: true},
      {value: "40/32", isAvailable: true},
      {value: "40/34", isAvailable: true},
      {value: "42/34", isAvailable: false}
    ],
    ssid: '012345',
    country: 'DE',
    language: 'de',
    caseId: 'case5'
  },
  {
    thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
    title: 'Case 6',
    description: 'Multi Variants Page (PDP & Outfit & Quick-View)',
    caseId: 'case6',
    userId: '123456789',
    ssid: '012345',
    country: 'DE',
    language: 'de',
    productPDP: {
      title: 'Slim-Fit Blouson aus Seersucker-Gewebe von BOSS',
      thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
      styleId: '50433876',
      colorIds: ['061', '407'],
      sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
    },
    productsOutfit: [
      {
        title: 'Slim-Fit Blouson aus Seersucker-Gewebe von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
        styleId: '50433876',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Slim-Fit Hose aus Seersucker-Gewebe mit Tunnelzugbund von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432920061.jpg',
        styleId: '50432920',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Sneakers aus Mesh, Veloursleder und Leder mit Schnürung von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432995460.jpg',
        styleId: '50432995',
        colorIds: ['460'],
        sizes: ['40', '41', '42', '43', '44', '45', '46']
      }
    ],
    productsQV: [
      {
        title: 'Slim-Fit Blouson aus Seersucker-Gewebe von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
        styleId: '50433876',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Slim-Fit Hose aus Seersucker-Gewebe mit Tunnelzugbund von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432920061.jpg',
        styleId: '50432920',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Sneakers aus Mesh, Veloursleder und Leder mit Schnürung von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432995460.jpg',
        styleId: '50432995',
        colorIds: ['460'],
        sizes: ['40', '41', '42', '43', '44', '45', '46']
      },
      {
        title: 'Slim-Fit Blouson aus Seersucker-Gewebe von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
        styleId: '50433876',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Slim-Fit Hose aus Seersucker-Gewebe mit Tunnelzugbund von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432920061.jpg',
        styleId: '50432920',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Sneakers aus Mesh, Veloursleder und Leder mit Schnürung von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432995460.jpg',
        styleId: '50432995',
        colorIds: ['460'],
        sizes: ['40', '41', '42', '43', '44', '45', '46']
      },
      {
        title: 'Slim-Fit Blouson aus Seersucker-Gewebe von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
        styleId: '50433876',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Slim-Fit Hose aus Seersucker-Gewebe mit Tunnelzugbund von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432920061.jpg',
        styleId: '50432920',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      }
    ]
  },
  {
    caseId: 'case7',
    thumbnail: 'https://www.fjallraven.com/globalassets/catalogs/fjallraven/f8/f816/f81698/f042/7323450506452_ss19_a_skogsoe_jacket_m_fjaellraeven_21.jpg?width=600&height=600&mode=BoxPad&bgcolor=fff&quality=80',
    title: 'PDP with multiple colors',
    description: 'A page view that contains one style in multiple colors.',
    country: 'US',
    language: 'en',
    userLanguage: '',
    styleId: 'F81698',
    colorIds: ['042', '030', '620', '555','666'],
    sizes: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL']
  },
  {
    caseId: 'case8',
    thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
    title: 'Outfit page',
    description: 'An outfit page that "Fit Finder" loads on every product.',
    country: 'DE',
    language: 'de',
    userLanguage: 'en',
    products: [
      {
        title: 'Slim-Fit Blouson aus Seersucker-Gewebe von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
        styleId: '50433876',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Slim-Fit Hose aus Seersucker-Gewebe mit Tunnelzugbund von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432920061.jpg',
        styleId: '50432920',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Sneakers aus Mesh, Veloursleder und Leder mit Schnürung von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432995460.jpg',
        styleId: '50432995',
        colorIds: ['460'],
        sizes: ['40', '41', '42', '43', '44', '45', '46']
      }
    ]
  },
  {
    caseId: 'case9',
    thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50423170960.jpg',
    title: 'Dynamic view render',
    description: 'A basic PDP that rerenders the view and removes our FF button',
    country: 'DE',
    language: 'de',
    userLanguage: 'en',
    productId: '50423170960',
    sizes: ['XS', 'S', 'M', 'L', 'XL'],
    styleId: '50423170'
  },
  {
    caseId: 'case10',
    thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50423170960.jpg',
    title: 'Product-listing page and Quickview',
    description: 'A product listing page that opens up product details on a quickview',
    country: 'DE',
    language: 'de',
    userLanguage: 'en',
    products: [
      {
        title: 'Slim-Fit Blouson aus Seersucker-Gewebe von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
        styleId: '50433876',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Slim-Fit Hose aus Seersucker-Gewebe mit Tunnelzugbund von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432920061.jpg',
        styleId: '50432920',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Sneakers aus Mesh, Veloursleder und Leder mit Schnürung von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432995460.jpg',
        styleId: '50432995',
        colorIds: ['460'],
        sizes: ['40', '41', '42', '43', '44', '45', '46']
      },
      {
        title: 'Slim-Fit Blouson aus Seersucker-Gewebe von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
        styleId: '50433876',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Slim-Fit Hose aus Seersucker-Gewebe mit Tunnelzugbund von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432920061.jpg',
        styleId: '50432920',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Sneakers aus Mesh, Veloursleder und Leder mit Schnürung von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432995460.jpg',
        styleId: '50432995',
        colorIds: ['460'],
        sizes: ['40', '41', '42', '43', '44', '45', '46']
      },
      {
        title: 'Slim-Fit Blouson aus Seersucker-Gewebe von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
        styleId: '50433876',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Slim-Fit Hose aus Seersucker-Gewebe mit Tunnelzugbund von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432920061.jpg',
        styleId: '50432920',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      }
    ]
  },
  {
    caseId: 'case11',
    thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50423170960.jpg',
    title: 'Multiple Sizes Alert (MSA)',
    description: 'MSA in a basic single color PDP.',
    country: 'DE',
    language: 'de',
    userLanguage: 'en',
    productId: '50423170960',
    sizes: ['XS', 'S', 'M', 'L', 'XL'],
    styleId: '50423170'
  },
  {
    caseId: 'case12',
    thumbnail: 'https://images.fitanalytics.com/garments/maje/300/MFPRO01279-B008.jpg',
    title: 'Multiple sizing systems',
    description: 'A single color PDP with multiple sizing systems',
    country: 'DE',
    language: 'en',
    productId: '01T71Q3-YWO',
    sizes: {
      'DE': ['30', '32', '34', '36', '38', '40', '42', '44'],
      'US': ['00', '0', '2', '4', '6', '8', '10', '12'],
      'UK': ['2', '4', '6', '8', '10', '12', '14', '16'],
      'FR': ['32', '34', '36', '38', '40', '42', '44', '46']
    }
  },
  {
    caseId: 'case13',
    thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50423170960.jpg',
    title: 'Adoption Flag',
    description: 'Adoption Flag in a basic single color PDP.',
    country: 'DE',
    language: 'de',
    userLanguage: 'en',
    productId: '50423170960',
    sizes: ['XS', 'S', 'M', 'L', 'XL'],
    styleId: '50423170'
  },
  {
    caseId: 'case14',
    thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50423170960.jpg',
    title: 'Disabled ATC on OOS sizes ',
    description: 'A plain PDP with disabled ATC when out-of-stock sizes are selected.',
    country: 'DE',
    language: 'de',
    userLanguage: 'en',
    productId: '123456789',
    sizes: ['XS', 'S', 'M', 'L', 'XL'],
    objectSizes: [
      { "value": "XS", "isAvailable": true },
      { "value": "S", "isAvailable": true },
      { "value": "M", "isAvailable": true },
      { "value": "L", "isAvailable": true },
      { "value": "XL", "isAvailable": false },
      { "value": "XXL", "isAvailable": false }
    ],
    styleId: '123456789'
  },
  {
    caseId: 'case15',
    title: 'Util Functions Testing page',
    description: 'A set of elements and data that will be used to test util functions like getAttribute.'
  },
  {
    caseId: 'case16',
    thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50423170960.jpg',
    title: 'Nested size elements',
    description: 'A basic single colored PDP with nested size elements.',
    country: 'DE',
    language: 'de',
    userLanguage: 'en',
    productId: '50423170960',
    sizes: ['XS', 'S', 'M', 'L', 'XL']
  },
  {
    caseId: 'case17',
    thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50423170960.jpg',
    title: 'Single Page Application (SPA)',
    description: 'A product listing page that opens up product details on a quickview or on the primary view/PDP.Types of integrations: Fit Finder on PLP (FFPLP), quickview, main PDP.',
    country: 'DE',
    language: 'de',
    userLanguage: 'en',
    products: [
      {
        title: 'Slim-Fit Blouson aus Seersucker-Gewebe von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
        styleId: '50433876',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Slim-Fit Hose aus Seersucker-Gewebe mit Tunnelzugbund von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432920061.jpg',
        styleId: '50432920',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Sneakers aus Mesh, Veloursleder und Leder mit Schnürung von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432995460.jpg',
        styleId: '50432995',
        colorIds: ['460'],
        sizes: ['40', '41', '42', '43', '44', '45', '46']
      },
      {
        title: 'Slim-Fit Blouson aus Seersucker-Gewebe von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
        styleId: '50433876',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Slim-Fit Hose aus Seersucker-Gewebe mit Tunnelzugbund von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432920061.jpg',
        styleId: '50432920',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Sneakers aus Mesh, Veloursleder und Leder mit Schnürung von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432995460.jpg',
        styleId: '50432995',
        colorIds: ['460'],
        sizes: ['40', '41', '42', '43', '44', '45', '46']
      },
      {
        title: 'Slim-Fit Blouson aus Seersucker-Gewebe von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50433876061.jpg',
        styleId: '50433876',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      },
      {
        title: 'Slim-Fit Hose aus Seersucker-Gewebe mit Tunnelzugbund von BOSS',
        thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432920061.jpg',
        styleId: '50432920',
        colorIds: ['061', '407'],
        sizes: ['44', '46', '48', '50', '52', '54', '56', '58', '60', '94', '102']
      }
    ],
    plp: {
      gender: 'm',
      category: 'upper'
    }
  },
  {
    thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50432995460.jpg',
    productId: '50432995460',
    styleId: '50432995',
    sizes: ['40', '41', '42', '43', '44', '45'],
    caseId: 'case18',
    title: 'Multiple size charts',
    description: 'A PDP with multiple size charts',
    country: 'DE',
    language: 'de',
    userLanguage: 'en'
  },
  {
    caseId: 'case19',
    thumbnail: 'https://images.fitanalytics.com/garments/defshop/300/366182.jpg',
    title: 'Alioop Product Page',
    description: 'A basic PDP with alioop products.',
    country: 'DE',
    language: 'de',
    userLanguage: 'en',
    productId: '01T71Q3-YWO',
    sizes: ['XS', 'S', 'M', 'L', 'XL'],
    styleId: '01T71Q3-YWO'
  },
  {
    caseId: 'case20',
    thumbnail: 'https://images.fitanalytics.com/garments/boss/300/50423170960.jpg',
    title: 'Merchant Consent Status',
    description: 'A basic PDP with a single colored product.',
    country: 'DE',
    language: 'de',
    userLanguage: 'en',
    userId: '123456789',
    productId: '50423170960',
    sizes: ['XS', 'S', 'M', 'L', 'XL'],
    styleId: '50423170'
  },
  {
    thumbnail: 'https://images.puma.com/image/upload/f_auto%2Cq_auto%2Cb_rgb:fafafa%2Cw_300%2Ch_300/global/847070/02/fnd/EEA/fmt/png/Evostripe-Damen-T-Shirt',
    title: 'Single PDP - Object Based with Osiris validation',
    description: 'PDP with data object validator - Osiris',
    productId: '847070_17',
    country: 'DE',
    language: 'en',
    userId: '123456789',
    shopSessionId: '_gid',
    sizes: [
      { "value": "XXS", "isAvailable": true },
      { "value": "XS", "isAvailable": true },
      { "value": "S", "isAvailable": true },
      { "value": "M", "isAvailable": true },
      { "value": "L", "isAvailable": false },
      { "value": "XL", "isAvailable": false }
    ],
    caseId: 'case25'
  }
];

export default testCases;

import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import './index.scss';

function Home() {
  const navigate = useNavigate();
  const onClickTesting = () => {
    navigate('testing');
  };
  const onClickShowcase = () => {
    navigate('showcase');
  };
  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col className="d-flex justify-content-end">
          <OverlayTrigger
            placement="top"
            delay={{ show: 50, hide: 400 }}
            overlay={renderTooltip}
          >
            <Button className="btn btn-secondary">Showcase</Button>
          </OverlayTrigger>
        </Col>
        <Col className="d-flex justify-content-left">
          <Button onClick={onClickTesting}>Testing</Button>
        </Col>
      </Row>
    </Container>
  );
}

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Coming soon...
  </Tooltip>
);

export default Home;

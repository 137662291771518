import merge from "lodash.merge";

const defaultIntegrationConfig = {
        name: 'default experiment config',
        base: {
          debug: true,
          shop: {
            prefix: 'alioop',
            country: () => 'US',
            language: () => 'en',
            userLanguage: {
              selector: 'meta[name="user-language"]',
              attr: 'content'
            }
          },

          scope: '.testCase',
          product: {
            container: '.row',

            hasManufacturedSizes: true,

            currentSerial: {
              selector: 'meta[name="product-id"]',
              attr: 'content'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              items: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              shouldSelectRecommended: true
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          },

          mockExperiments: {
            'AB': 'fita'
          },

          experiments: {
            // each key as an experiment ID, each value is its configuration
            "AB": {
              effects: {
                "fita": {
                  sizeChartButton: {
                    shouldHide: true
                  }
                },
                "control": {
                  button: null,
                  sizeChartButton: {
                    shouldHide: false
                  }
                }
              }
            }
          }
        }
      },

      defaultWithoutExperiments = merge({}, defaultIntegrationConfig, {
        base: {
          mockExperiments: null,
          experiments: null
        }
      }),

      integrationConfigs = [
        defaultIntegrationConfig,
        merge({}, defaultIntegrationConfig, {
          name: 'default in control funnel',
          base: {
            mockExperiments: {
              'AB': 'control'
            }
          }
        }),
        merge({}, defaultWithoutExperiments, {
          name: 'no-experiment config',
          base: {
            shop: {
              country: {// collecting the country that doesn't have active experiments in the DB
                selector: 'meta[name="country"]',
                attr: 'content'
              },
              language: {
                selector: 'meta[name="language"]',
                attr: 'content'
              }
            },
            experiments: {
              AB: null
            }
          }
        }),

        merge({}, defaultWithoutExperiments, {
          name: 'external experiment config',
          base: {
            shop: {
              country: {
                selector: 'meta[name="country"]',
                attr: 'content'
              },
              language: {
                selector: 'meta[name="language"]',
                attr: 'content'
              }
            },
            experiments: {
              "external_button_engagement": {
                // external tests need to be marked as such
                // so that they can be reported to metrics though meterExternalAB
                isExternal: true,
                // required for external tests can be a direct value or a function
                variant: {
                  node: window,
                  path: 'fitafunnel'
                },
                effects: {
                  "button-w-img": null,
                  "button-w/o-img": {
                    button: {
                      style: {
                        'image': {
                          'display': 'none'
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }),
        merge({}, defaultWithoutExperiments, {
          name: 'micro_995 in control',
          base: {
            mockExperiments: {
              micro_995: 'control'
            }
          }
        }),
        merge({}, defaultWithoutExperiments, {
          name: 'micro_995 in fita',
          base: {
            mockExperiments: {
              micro_995: 'fita_full'
            }
          }
        })
      ];

export { defaultIntegrationConfig, integrationConfigs };

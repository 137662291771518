import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AddToCartButton from "../AddToCartButton";
import SizeChart from "../SizeChart";
import Sizes from "../Sizes";
import Thumbnail from "../Thumbnail";
import Title from "../Title";
import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import {
  defaultIntegrationConfig,
  integrationConfigs,
} from "../../../integration_configs/config20";
import { useIntegration } from "./useIntegration";
import { useShopSessionId } from "../../../hooks/useShopSessionId";

function Case20(props = {}) {

  useIntegration(defaultIntegrationConfig);

  useShopSessionId(props.caseId);

  return (
    <>
      <ConfigPane configs={integrationConfigs} />
      <Container className="testCase">
        <Row>
          <Thumbnail src={props.thumbnail} />
          <Col>
            <Meta {...props} />
            <Title title={props.title} />
            <p>{props.description}</p>
            <Sizes sizes={props.sizes} availability={4 / 5} />
            <SizeChart />
            <AddToCartButton />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { Case20 };

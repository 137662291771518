import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setSelectedSecondarySize, set2DSizesSeparator } from "./cases/caseProductsSlice";
import { useCaseProduct } from "./cases/useCaseProducts";

import Sizes from './Sizes';

/* @props:
  - sizeData: is an array containing the sizes values composed of the 2D value (exp: '32/32', 'S normal' ....)
  - selectedSecondarySize: by default a half size value is selected in order to display related second half values
  - separator: this is simply the split between 1D and 2D sizes
*/


const TwoDimensionalSizes = ({ productKey, sizeData = [], disableButton = true, selectedSecondarySize = '', separator = ' ' }) => {
  const dispatch = useDispatch(),
        { product } = useCaseProduct(productKey),
        selectedFit = product.selectedSecondarySize,
        sizes = {};

  sizeData.forEach((item) => {
    const [fit, size] = (item || '').split(separator);
    if (fit in sizes) {
      sizes[fit].push(size);
    } else {
      sizes[fit] = [size];
    }
  });

  const [fitSizes, setFitSizes] = useState([]);
  let fits = Object.keys(sizes);

  useEffect(() => {
    setSelectedFit(selectedSecondarySize);
    dispatch(set2DSizesSeparator({
      id: productKey,
      value: separator
    }));
  }, []);

  useEffect(() => {
    setFitSizes(sizes[selectedFit]);
  }, [selectedFit]);


  const onFitChange = (e) => {
    e.preventDefault();
    setSelectedFit(e.target.value);
  };

  const setSelectedFit = (size) => {
    let payload = {
      id: productKey,
      value: size
    };
    dispatch(setSelectedSecondarySize(payload));
  };

  const isFitSelected = fitValue => (fitValue === selectedFit);

  return (
    <>
      <h4>Fit: </h4>

      { fits.map((fit,index) =>
        <Button
          key={fit + index}
          className={`fit-size ${isFitSelected(fit) ? 'selected': ''}`}
          style= {
            {
              color: isFitSelected(fit) ? 'black' : 'grey',
              width: 'fit-content',
              margin: '0 5px',
              padding: '0px'
            }
          }
          variant='link'
          onClick={onFitChange}
          value={fit}
        >
          {fit}
        </Button>)
      }

      <br/>
      <br/>

      { Boolean(fitSizes && fitSizes.length) ? <Sizes
        sizes={fitSizes}
        availability={(fitSizes.length - 1)/(fitSizes.length)}
        disableButton={disableButton}
      /> : <></> }
    </>
  );
};

export default TwoDimensionalSizes;

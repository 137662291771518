import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setSelectedSize } from "./cases/caseProductsSlice";
import { useCaseProduct } from "./cases/useCaseProducts";

function Sizes({productKey, sizes = [], availability = 1, disableButton = true, onClick=()=>{}}) {
  const dispatch = useDispatch(),
        {product} = useCaseProduct(productKey),
        selectedSize = product.selectedSize;

  useEffect(() => {
    return () => setSize(null, null);
  }, []);

  const onSizeClick = (ev) => {
    setSize(ev.target.value, !/out-of-stock/.test(ev.target.className));
    onClick();
  };

  const setSize = (size, isAvailable) => {
    let payload = {
      id: productKey,
      value: size,
      isAvailable: isAvailable
    };
    dispatch(setSelectedSize(payload));
  };
  return (
    <div className = 'sizes mb-3'>
      <h6>Sizes: </h6>
      {
        sizes.map((size, index, array) => {
          let isAvailable = index/(array.length-1) <= availability,
              isSelected = selectedSize === size,
              className = `size-button ${isSelected ? 'selected' : ''}
                             ${!isAvailable ? 'out-of-stock' : ''}
                             me-2 mb-2 
                            `.replace(/(\s)+/g, ' ').trim();
          return <Button
            key={size + index}
            className={className}
            value={size}
            disabled={!isAvailable && disableButton}
            title={size}
            variant={isSelected ? 'secondary':'outline-secondary'}
            onClick={onSizeClick}
          >
            {size}
          </Button>;
        })
      }
    </div>
  );
}

export default Sizes;

import { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { fetchWidgetPDP } from "../../../integration";


function Case15() {
  const [treeName, setTreeName] = useState('oak');
  const onTreeNameChange = (ev) => {
    setTreeName(ev.target.value);
  };

  useEffect(() => {
    fetchWidgetPDP();
    document.cookie = 'ssid=ssidCookie';
  }, []);

  return (
    <>
      <Alert variant='info'>
      This page is used for automated testing.
      </Alert>
      <div className='context m-5'>
        <div className='mb-3'>
          <label className='me-3' htmlFor='textInput'>Text input</label>
          <input id='textInput' name='textInput' type='text'/>
        </div>
        <div className='mb-3'>
          <label className='me-3' htmlFor='theTree'>Tree Name</label>
          <input id='theTree' name='tree' type='text' value={treeName} onChange={onTreeNameChange}/>
        </div>
        <div className='mb-3' style={{display: 'flex'}}>
          {
            ['red', 'green', 'blue'].map((color) => {
              return <Button key={color}
                className = 'colorButton me-3'
                value={color}
                style={{background: color, borderColor: color}}
              >
                {color}
              </Button>;
            })
          }
        </div>
      </div>
      <div className='independentNode m-5'>
        <p className='plainLine' >A plain line of text.</p>
      </div>
    </>
  );
}

export { Case15 };

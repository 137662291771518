import ListingPage from "./ListingPage";
import TestingTile from "./TestingTile";
import { useSelector } from "react-redux";
import { getCases } from "../../data/caseSlice";

function MainTesting() {
  const testCases = useSelector(getCases);
  return (
    <div className='mainTesting'>
      <ListingPage Tile={TestingTile} items={testCases} itemKeyName={'caseId'} />
    </div>
  );
}

export default MainTesting;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isIntegrationLaunched: false,
  productData: {}
};

export const caseSlice = createSlice({
  name: 'case',
  initialState,
  reducers: {
    launchIntegration: (state) => {
      state.isIntegrationLaunched = true;
    },
    dismissIntegration: (state) => {
      state.isIntegrationLaunched = false;
    },
    setProductData: (state, action) => {
      state.productData = action.payload;
    },
    showQuickView: (state) => {
      state.showQuickView = true;
    },
    hideQuickView: (state) => {
      state.showQuickView = false;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    unsetCountry: (state) => {
      state.country = null;
    },
    setSizingSystem: (state, action) => {
      state.sizingSystem = action.payload;
    },
    unsetSizingSystem: (state) => {
      state.sizingSystem = null;
    }
  }
});

export const {
  launchIntegration,
  dismissIntegration,
  setProductData,
  showQuickView,
  hideQuickView,
  setCountry,
  unsetCountry,
  setSizingSystem,
  unsetSizingSystem
} = caseSlice.actions;

export const getCaseState = (state) => state.case;
export const getProductData = (state) => state.case.productData;

export default caseSlice.reducer;

import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setSelectedColor } from "./cases/caseProductsSlice";
import { useCaseProduct } from "./cases/useCaseProducts";

function Swatches({productKey, colors = []}) {
  const dispatch = useDispatch(),
        {product} = useCaseProduct(productKey),
        selectedColor = product.selectedColor;

  useEffect(()=> {
    setSwatch(colors[0]);
    return () => setSwatch(null);
  }, []);

  const onSwatchClick = (ev) => {
    setSwatch(ev.target.value);
  };

  const setSwatch = (swatchValue) => {
    let payload = {
      id: productKey,
      value: swatchValue
    };
    dispatch(setSelectedColor(payload));
  };

  return (
    <div className='colors mb-3'>
      <h6>Colors: </h6>
      {
        colors.map((color) => {
          let isSelected = selectedColor === color;
          return <Button
            key={color}
            className={`${isSelected ? "selected" : ""} me-2 colorId`}
            title={color}
            value={color}
            variant={isSelected ? 'secondary':'outline-secondary'}
            onClick={onSwatchClick}
          >
            {color}
          </Button>;
        })
      }
    </div>
  );
}


export {Swatches};

import merge from "lodash.merge";

const defaultIntegrationConfig = {
        name: 'Default',
        base: {
          debug: true,
          shop: {
            prefix: 'boss',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userId: {
              selector: 'meta[name="user-id"]',
              attr: 'content'
            }
          },

          scope: '.testCase',
          product: {
            container: '.product',

            hasManufacturedSizes: true,

            currentSerial: {
              selector: 'meta[name="product-id"]',
              attr: 'content'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              items: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              secondary: {
                items: 'button.fit-size',
                code: {
                  selector: '.',
                  prop: 'value'
                },
                isAvailable: {
                  selector: '.',
                  is: ':not([disabled])'
                },
                isSelected: {
                  selector: '.',
                  is: '.selected'
                },
                delimiter: ' - '
              },
              shouldSelectRecommended: true
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after',
            style: {
              'button': {
                'width': 'fit-content'
              }
            }
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          }
        }
      },

      showSizeChartConfig = merge({}, defaultIntegrationConfig, {
        name: 'showSizeChartConfig',
        base: {
          sizeChartButton: { shouldHide: false }
        }
      }),

      objectBasedConfig = {
        name: 'objectBasedConfig',
        base: {
          debug: true,
          shop: {
            node: window,
            prefix: 'boss',
            language: {
              path: 'fitAnalyticsData.language'
            },
            country: {
              path: 'fitAnalyticsData.country'
            },
            userId: {
              path: 'fitAnalyticsData.userId'
            },
            shopSessionId: {
              path: 'fitAnalyticsData.ssid'
            },
            isReady: () => window.fitAnalyticsData
          },

          product: {
            scope: '.testCase',
            container: '.row',

            hasManufacturedSizes: true,

            currentSerial: {
              node: window,
              path: 'fitAnalyticsData.productId'
            },

            thumbnail: {
              node: window,
              path: 'fitAnalyticsData.thumbnail'
            },

            addToCart: null,

            sizes: {
              node: window,
              items: {
                path: 'fitAnalyticsData.objectSizes'
              },
              code: {
                path: 'value'
              },
              isAvailable: {
                path: 'isAvailable'
              },
              shouldSelectRecommended: false
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after',
            style: {
              'button': {
                'width': 'fit-content'
              }
            }
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          }
        }
      },
      legacySizesElem = merge({}, defaultIntegrationConfig, {
        name: 'Legacy Size "elem"',
        base: {
          product: {
            sizes: {
              items: null,
              elem: '.sizes button',
              secondary: {
                items: null,
                elem: 'button.fit-size'
              }
            }
          }
        }
      }),

      integrationConfigs = [
        defaultIntegrationConfig,
        showSizeChartConfig,
        objectBasedConfig,
        legacySizesElem
      ];

export { integrationConfigs, defaultIntegrationConfig };

import { useSelector } from "react-redux";
import { getCaseProducts } from "./caseProductsSlice";

function useCaseProduct(productKey) {
  const products = useSelector(getCaseProducts),
        product = products[productKey] || products.default || {};

  return {products, product};
}

export {useCaseProduct};

import ConfigPane from "./ConfigPane";
import ListingPage from "../ListingPage";
import Meta from "../Meta";
import ProductTile from "../ProductTile";
import QuickView from "../Quickview";
import { integrationConfigs, defaultIntegrationConfig } from "../../../integration_configs/config10";
import { useIntegration } from "./useIntegration";

function Case10(props={}) {
  useIntegration(defaultIntegrationConfig);
  return (
    <>
      <ConfigPane configs={integrationConfigs} />
      <div className='testCase'>
        <Meta {... props} />
        <ListingPage className='plp' Tile={ProductTile} items={props.products}/>
        <QuickView />
      </div>
    </>
  );
}

export { Case10 };

import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCaseState, setSizingSystem, unsetSizingSystem } from "./cases/CaseSlice";

function SizingSystems({systems =[]}) {
  const dispatch = useDispatch(),
        caseState = useSelector(getCaseState),
        selectedSizingSystem = caseState.sizingSystem,
        country = caseState.country;

  useEffect(()=>{
    dispatch(setSizingSystem(systems[0]));
    return () => {
      dispatch(unsetSizingSystem());
    };
  }, [country]);

  const onSizingSystemClick = (e) => {
    dispatch(setSizingSystem(e.target.value));
  };

  return (
    <div className='sizing-systems d-inline-flex mb-2' style={{alignItems: 'baseline'}}>
      <h6>Sizing Systems:</h6>
      {
        systems.map((system) => {
          let isSelected = system === selectedSizingSystem;
          return <Button
            key={system}
            className={`ms-2 ${isSelected? 'selected': ''}`}
            style= {
              {
                color: isSelected ? 'black' : 'grey',
                width: 'fit-content',
                padding: '0px'
              }
            }
            variant='link'
            value={system}
            onClick={onSizingSystemClick}>
            {system}
          </Button>;

        })
      }
    </div>
  );
}

export {
  SizingSystems
};

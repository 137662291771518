import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const getCartItemIndex = (cartItems, action) => {
  return cartItems.findIndex(item => {
    const isSameId = item.productId === action.payload.productId,
          isSameSize = item.size === action.payload.size;
    return (isSameId && isSameSize) || false;
  });
};

export const addAsyncItemToCart = createAsyncThunk(
  'cart/addAsyncItemToCart',
  async (cartItem) => {
    const response = await new Promise(resolve =>
      setTimeout(() => resolve({ data: cartItem }), 1500)
    );
    return response.data;
  }
);

export const removeAsyncItemFromCart = createAsyncThunk(
  'cart/removeAsyncItemFromCart',
  async (cartItem) => {
    const response = await new Promise(resolve =>
      setTimeout(() => resolve({ data: cartItem }), 1500)
    );
    return response.data;
  }
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    async: false,
    cartItems: [],
    status: '',
    show: false
  },
  reducers: {
    setAsync(state, action) {
      state.async = action.payload;
    },
    setShow(state, action) {
      state.show = action.payload;
    },
    addItemToCart: (state, action) => {
      const itemIndex = getCartItemIndex(state.cartItems, action);
      if (itemIndex < 0) {
        state.cartItems.push({
          ...action.payload,
          id: new Date().getTime(),
          quantity: 1
        });
      } else {
        state.cartItems[itemIndex].quantity += 1;
      }
      state.show = action.payload.showCartOnAdd;
    },
    removeItemFromCart(state, action) {
      const itemIndex = getCartItemIndex(state.cartItems, action);
      state.cartItems = state.cartItems.filter((_, idx) => idx !== itemIndex);
    },
    emptyCart(state) {
      state.cartItems = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addAsyncItemToCart.pending, (state) => {
      state.status = 'loading';
    })
      .addCase(addAsyncItemToCart.fulfilled, (state, action) => {
        const itemIndex = getCartItemIndex(state.cartItems, action);
        if (itemIndex < 0) {
          state.cartItems.push({
            ...action.payload,
            id: new Date().getTime(),
            quantity: 1
          });
        } else {
          state.cartItems[itemIndex].quantity += 1;
        }
        state.show = action.payload.showCartOnAdd;
      })
      .addCase(removeAsyncItemFromCart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeAsyncItemFromCart.fulfilled, (state, action) => {
        const itemIndex = getCartItemIndex(state.cartItems, action);
        state.cartItems = state.cartItems.filter((_, idx) => idx !== itemIndex);
      });
  }
});

export const {
  addItemToCart,
  removeItemFromCart,
  emptyCart,
  setAsync,
  setShow
} = cartSlice.actions;

export const getCartItems = state => state.cart.cartItems,
      getShow = state => state.cart.show,
      getAsync = state => state.cart.async;

export default cartSlice.reducer;

import ConfigPane from "./ConfigPane";
import ListingPage from "../ListingPage";
import Meta from "../Meta";
import { PlpMeta } from "../Meta";
import ProductTile from "../ProductTile";
import QuickView from "../Quickview";
import { integrationConfigs, defaultIntegrationConfig } from "../../../integration_configs/config17";
import { useIntegration } from "./useIntegration";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Thumbnail from "../Thumbnail";
import Title from "../Title";
import Sizes from "../Sizes";
import AddToCartButton from "../AddToCartButton";
import SizeChartButton from "../SizeChart";
import { Swatches } from "../Swatches";
import { Button } from "react-bootstrap";

function Case17(props={}) {
  const navigate = useNavigate(),
        [pdpProps, setPdpProps] = useState({});

  useIntegration(defaultIntegrationConfig);

  const enterPdp = (incomingProps) => {
    setPdpProps(incomingProps);
    navigate(`./${pdpProps.styleId}`);
  };
  return (
    <>
      <ConfigPane configs={integrationConfigs} />
      <div className='testCase'>
        <Meta {... props} />
        <PlpMeta {... props.plp} />
        <Container className="mt-3">
          <Row>
            <Title title={props.title} />
            <p>{props.description}</p>
          </Row>
        </Container>
        <Routes>
          <Route exact path="/" element={<PLP {... props} enterPdp={enterPdp}/>} />
          <Route exact path="/:styleId" element={<PDP {... pdpProps}/>} />
        </Routes>
      </div>
    </>
  );
}

function PLP(props={}) {
  return (
    <>
      <ListingPage className='plp' Tile={ProductTile} items={props.products} enterPdp={props.enterPdp}/>
      <QuickView enterPdp={props.enterPdp}/>
    </>
  );
}

function PDP(props={}) {
  const navigate = useNavigate();
  let hasProps = Boolean(props.styleId);
  return (
    !hasProps ?
      <Navigate replace to="../" /> :
      <>
        <Button className='m-3 go-back' variant='link' onClick={()=>navigate(-1)}>Go Back</Button>
        <Container className='pdp my-3'>
          <Row className='product'>
            <Thumbnail src = {props.thumbnail} />
            <Col>
              <Meta {... props}/>
              <Title title={props.title} />
              <p>{props.description}</p>
              <Swatches colors={props.colorIds} />
              <Sizes sizes={props.sizes}
                availability={4/5}
              />
              <SizeChartButton />
              <AddToCartButton />
            </Col>
          </Row>
        </Container>
      </>
  );
}

export { Case17 };

import { Container, Row, Col } from "react-bootstrap";
import AddToCartButton from "../AddToCartButton";
import SizeChart from "../SizeChart";
import SizesAdvanced from "../SizesAdvanced";
import Thumbnail from "../Thumbnail";
import Title from "../Title";
import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import { defaultIntegrationConfig, integrationConfigs } from '../../../integration_configs/config16';
import { useIntegration } from "./useIntegration";

function Case16(props = {}) {

  useIntegration(defaultIntegrationConfig);

  return (<>
    <ConfigPane configs={integrationConfigs}/>
    <Container className='testCase'>
      <Row>
        <Thumbnail src = {props.thumbnail}/>
        <Col>
          <Meta {... props}/>
          <Title title={props.title}/>
          <p>{props.description}</p>
          <SizesAdvanced sizes={props.sizes}
            availability={4/5}
          />
          <SizeChart />
          <AddToCartButton />
        </Col>
      </Row>
    </Container>
  </>);
}

export { Case16 };

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import testCasesReducer from "../data/caseSlice";
import pageConfigReducer from "../components/Testing/PageConfigSlice";
import caseReducer from "../components/Testing/cases/CaseSlice";
import caseProductsReducer from '../components/Testing/cases/caseProductsSlice';
import cartReducer from '../components/Testing/cartSlice';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers({
  testCases: testCasesReducer,
  pageConfig: pageConfigReducer,
  case: caseReducer,
  caseProducts: caseProductsReducer,
  cart: cartReducer
});

const persistConfig = {
  key: 'pageConfig',
  version: 1,
  whitelist: ['pageConfig'],
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

const persistor = persistStore(store);

export {store, persistor};

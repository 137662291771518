import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDefaultIntegration: false,
  isHighlightActive: false,
  shouldMountIntegrationByDefault: true
};

export const pageConfigSlice = createSlice({
  name: "pageConfig",
  initialState,
  reducers: {
    toggleDefaultIntegration: (state) => {
      state.isDefaultIntegration = !state.isDefaultIntegration;
    },
    toggleHighlight: (state) => {
      state.isHighlightActive = !state.isHighlightActive;
    },
    toggleShouldMountIntegrationByDefault: (state) => {
      state.shouldMountIntegrationByDefault =
        !state.shouldMountIntegrationByDefault;
    }
  }
});

export const {
  toggleDefaultIntegration,
  toggleHighlight,
  toggleShouldMountIntegrationByDefault
} = pageConfigSlice.actions;

export const getPageConfig = (state) => state.pageConfig;

export default pageConfigSlice.reducer;

import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import { Container } from "react-bootstrap";
import { integrationConfigs, defaultIntegrationConfig } from '../../../integration_configs/config8';
import { OutfitProduct } from "../OutfitProduct";
import { useIntegration } from "./useIntegration";

function Case8(props={}) {
  useIntegration(defaultIntegrationConfig);
  return (
    <>
      <ConfigPane configs={integrationConfigs} />
      <div className='testCase'>
        <Meta {... props} />
        <Container className='outfit'>
          {
            props.products.map((product, index) => {
              return <OutfitProduct key={index} {... product} />;
            })
          }
        </Container>
      </div>
    </>
  );
}

export { Case8 };

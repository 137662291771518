import { Button } from "react-bootstrap";
import { useCaseProduct } from "./cases/useCaseProducts";
import { useDispatch, useSelector } from "react-redux";
import { addItemToCart, addAsyncItemToCart, getAsync } from "./cartSlice";

function AddToCartButton({ productId, thumbnail, productKey, showCartOnAdd=true}) {
  const dispatch = useDispatch(),
        { product } = useCaseProduct(productKey),
        size = product.selectedSize || '',
        separator = product.sizesSeparator || '',
        secondarySize = product.selectedSecondarySize || '',
        fullSize = `${size}${separator}${secondarySize}`,
        isAsync = useSelector(getAsync),
        onAddToCart = () => {
          let cartItem = {
            productId, thumbnail,
            size: fullSize,
            showCartOnAdd: showCartOnAdd
          };
          dispatch(isAsync ?
            addAsyncItemToCart(cartItem) :
            addItemToCart(cartItem)
          );
        };

  let isDisabled = product.selectedSize && product.isSizeAvailable ? false : true;

  return (
    <>
      <Button className='add-to-cart'
        variant="primary"
        disabled={isDisabled}
        onClick={onAddToCart}>
          Add To The Cart
      </Button>
    </>
  );
}

export default AddToCartButton;

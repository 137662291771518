import TestingHeader from "./Header";
import MainTesting from "./Main";
import { Route, Routes } from 'react-router-dom';
import TestCase from "./TestCase";
import PageConfig from "./PageConfig";


function Testing() {
  return (
    <div>
      <TestingHeader />
      <Routes>
        <Route exact path="/" element={<MainTesting />} />
        <Route exact path="/:id/*" element={<TestCase />} />
        <Route exact path="/page-config" element={<PageConfig />} />
      </Routes>
    </div>
  );
}

export default Testing;

import { Container, Col, Row } from "react-bootstrap";
import NotFound from "../NotFound";

function ListingPage({items =[], Tile, itemKeyName, className, enterPdp = ()=>{}}) {
  if (!Tile) return <NotFound />;
  return (
    <Container className={className}>
      <Row>
        <div className='fitfinder-plp-button'></div>
      </Row>
      <Row gutter={40} lg={4} md={3} sm={2} xs={1}>
        {
          items.map ((item, index) => {
            return <Col key={item[itemKeyName] || index} className="p-2">
              <Tile {... item} enterPdp={enterPdp}/>
            </Col>;
          })
        }
      </Row>
    </Container>
  );
}

export default ListingPage;

const defaultIntegrationConfig = {
        name: 'Default',
        base: {
          debug: true,
          shop: {
            prefix: 'boss',
            node: '.testCase',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userId: {
              selector: 'meta[name="user-id"]',
              attr: 'content'
            }
          },
          product: {
            container: '.quickview .row',

            hasManufacturedSizes: true,

            colorSeparator: '',
            styleId: {
              selector: 'meta[name="style-id"]',
              attr: 'content'
            },

            currentColorId: {
              selector: '.colors .colorId.selected',
              attr: 'value'
            },

            colorIds: {
              selector: '.colors .colorId',
              attr: 'value'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              elem: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              shouldSelectRecommended: true
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: '.size-chart',
            shouldHide: true
          }
        }
      },

      integrationConfigs = [ defaultIntegrationConfig ];

export { integrationConfigs, defaultIntegrationConfig };

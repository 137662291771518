import { Container, Row, Col } from "react-bootstrap";
import AddToCartButton from "../AddToCartButton";
import Sizes from "../Sizes";
import Thumbnail from "../Thumbnail";
import SizeChart from "../SizeChart";
import Title from "../Title";
import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import Cart from "../Cart";
import { useIntegration } from "./useIntegration";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setProduct } from "./caseProductsSlice";
import { addItemToCart, addAsyncItemToCart, getAsync, getCartItems } from "../cartSlice";
import { integrationConfigs, defaultIntegrationConfig } from '../../../integration_configs/config3';

function Case3(props = {}) {
  const dispatch = useDispatch(),
        productKey = props.productId,
        isAsync = useSelector(getAsync),
        cartItems = useSelector(getCartItems);


  useIntegration(defaultIntegrationConfig);

  useEffect(() => {
    dispatch(setProduct({id: productKey, product: {}}));
  }, []);

  window.fitAnalyticsData = {
    ...props,
    operations: {
      addToCart: (serial, size) => {
        const productObj = {
          productId: serial.split('-').pop(),
          thumbnail: props.thumbnail,
          size: size,
          showCartOnAdd: true
        };
        dispatch(isAsync ? addAsyncItemToCart(productObj) : addItemToCart(productObj));
      },
      getCartItems: () => cartItems
    }
  };

  return (<>
    <ConfigPane configs={integrationConfigs}/>
    <Container className='testCase'>
      <Row className='add-to-cart'>
        <Col sm={8}></Col>
        <Col sm={4}>
          <Cart placement="end" name="end"></Cart>
        </Col>
      </Row>
      <Row className='product'>
        <Thumbnail src = {props.thumbnail}/>
        <Col>
          <Meta {...props}/>
          <Title title={props.title}/>
          <p>{props.description}</p>
          <Sizes sizes={props.sizes.map(size => size.value)}
            availability={4/6}
          />
          <SizeChart />
          <AddToCartButton
            productId= {props.productId}
            thumbnail = {props.thumbnail}/>
        </Col>
      </Row>
    </Container>
  </>);
};

export { Case3 };

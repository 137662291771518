import { Container, Row, Col } from "react-bootstrap";
import { useIntegration } from "./useIntegration";
import AddToCartButton from "../AddToCartButton";
import SizeChart from "../SizeChart";
import Sizes from "../Sizes";
import Thumbnail from "../Thumbnail";
import Title from "../Title";
import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import Cart from "../Cart";
import { addItemToCart, addAsyncItemToCart, getAsync, getCartItems } from "../cartSlice";
import { integrationConfigs, defaultIntegrationConfig } from "../../../integration_configs/config14";
import { useDispatch, useSelector } from "react-redux";
import { getCaseProducts } from "../cases/caseProductsSlice";


function Case14(props = {}) {
  const dispatch = useDispatch(),
        isAsync = useSelector(getAsync),
        cartItems = useSelector(getCartItems),
        products = useSelector(getCaseProducts),
        caseProduct = products.default || {};

  useIntegration(defaultIntegrationConfig);
  window.fitAnalyticsData = {
    ...props,
    operations: {
      addToCart: (serial, size) => {
        const productObj = {
          productId: serial.split('-').pop(),
          thumbnail: props.thumbnail,
          size: size
        };
        dispatch(isAsync ? addAsyncItemToCart(productObj) : addItemToCart(productObj));
      },
      getCartItems: () => cartItems,
      getCurrentSize: () => caseProduct.selectedSize
    }
  };

  return (<>
    <ConfigPane configs={integrationConfigs}/>
    <Container className='testCase'>
      <Row className='cart'>
        <Col sm={8}></Col>
        <Col sm={4}>
          <Cart placement="end" name="end"></Cart>
        </Col>
      </Row>
      <Row className='product'>
        <Thumbnail src = {props.thumbnail}/>
        <Col>
          <Meta {... props}/>
          <Title title={props.title}/>
          <p>{props.description}</p>
          <Sizes sizes={props.sizes}
            availability={4/5}
            disableButton={false}
          />
          <SizeChart />
          <AddToCartButton
            productId= {props.productId}
            thumbnail = {props.thumbnail}
          />
        </Col>
      </Row>
    </Container>
  </>);
}

export { Case14 };

import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { getCaseState} from "./CaseSlice";
import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import Title from "../Title";
import Sizes from "../Sizes";
import Thumbnail from "../Thumbnail";
import AddToCartButton from "../AddToCartButton";
import SizeChartButton from "../SizeChart";
import { CountrySelect } from "../CountrySelect";
import { SizingSystems } from "../SizingSystem";
import { integrationConfigs, defaultIntegrationConfig } from "../../../integration_configs/config12";
import { useIntegration } from "./useIntegration";

function Case12(props={}) {
  const caseState = useSelector(getCaseState);
  const selectedSizingSystem = caseState.sizingSystem;
  const country = caseState.country;
  const sizingSystems = [country || "US", "FR", "US", "UK"];

  useIntegration(defaultIntegrationConfig);

  return (
    <>
      <ConfigPane configs={integrationConfigs} />
      <div className='d-flex justify-content-end me-5 mt-3'>
        <CountrySelect countries={['DE', 'US', 'UK']}/>
      </div>
      <Container className='testCase'>
        <Row>
          <Thumbnail src = {props.thumbnail}/>
          <Col>
            <Meta {... props} country={country}/>
            <Title title={props.title}/>
            <p>{props.description}</p>
            <SizingSystems systems={sizingSystems} />
            <Sizes sizes={props.sizes[selectedSizingSystem]} />
            <SizeChartButton />
            <AddToCartButton />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { Case12 };

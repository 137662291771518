import merge from "lodash.merge";

const defaultIntegrationConfig = {
        name: 'Default',
        base: {
          debug: true,
          shop: {
            prefix: 'boss',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userLanguage: {
              selector: 'meta[name="user-language"]',
              attr: 'content'
            }
          },

          product: {
            scope: '.testCase',
            container: '.row',

            hasManufacturedSizes: true,

            currentSerial: {
              selector: 'meta[name="product-id"]',
              attr: 'content'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              elem: '.sizes div.sizeOption',
              code: {
                selector: 'span',
                prop: 'text'
              },
              isAvailable: {
                selector: 'button',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: 'button',
                is: '.selected'
              },
              toSelect: 'button',
              shouldSelectRecommended: true
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          }

        }
      },

      integrationConfigs = [
        defaultIntegrationConfig,
        merge({}, defaultIntegrationConfig, {
          name: 'disable preselect',
          base: {
            product: {
              sizes: {
                shouldSelectRecommended: false
              }
            }
          }
        })
      ];

export { defaultIntegrationConfig, integrationConfigs };

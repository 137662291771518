import merge from "lodash.merge";

const defaultIntegrationConfig = {
        name: 'Default',
        base: {
          debug: true,
          shop: {
            prefix: 'fjallraven',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userId: {
              selector: 'meta[name="user-id"]',
              attr: 'content'
            },
            shopSessionId: {
              cookie: 'ssid_fjallraven'
            }
          },

          scope: '.testCase',
          product: {
            container: '.row',

            hasManufacturedSizes: true,

            colorSeparator: '-',
            styleId: {
              selector: 'meta[name="style-id"]',
              attr: 'content'
            },

            currentColorId: {
              selector: '.colors .colorId.selected',
              attr: 'value'
            },

            colorIds: {
              selector: '.colors .colorId',
              attr: 'value'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              items: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              shouldSelectRecommended: true
            }
          },

          button: {
            shouldShowOutOfScaleText: true,
            shouldShowOutOfStockText: true,
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          }
        }
      },

      uniColorConfig = merge({}, defaultIntegrationConfig, {
        name: 'Config without multi color',
        base: {
          product: {
            // I needed to specifically set colorIds to be NULL for testing purposes
            colorIds: null
          }
        }
      }),

      recommendationText = merge({}, defaultIntegrationConfig, {
        name: "Recommendation Text Element",
        base: {
          recommendationTexts: [
            {
              key: 'primary',
              anchor: '.sizes',
              anchorPlacement: 'after',
              text: {
                "en": {
                  "informal": {
                    "default": "",
                    "recommendation": "Size <b>{{size}}</b> will fit you best",
                    "outOfStock": "Your size <b>{{size}}</b> is not available",
                    "outOfScale": "No matching size"
                  }
                }
              }
            }
          ]
        }
      }),

      recommendationTextOOS = merge({}, defaultIntegrationConfig, {
        name: "Recommendation Text Element OOS",
        base: {
          recommendationTexts: [
            {
              key: 'primary',
              anchor: '.sizes',
              anchorPlacement: 'after',
              shouldShowOutOfStockText: true,
              shouldShowOutOfScaleText: true,
              text: {
                "en": {
                  "informal": {
                    "default": "",
                    "recommendation": "Size <b>{{size}}</b> will fit you best",
                    "outOfStock": "Your size <b>{{size}}</b> is not available",
                    "outOfScale": "No matching size"
                  }
                }
              }
            }
          ]
        }
      }),

      integrationConfigs = [ defaultIntegrationConfig, uniColorConfig, recommendationText, recommendationTextOOS ];

export { integrationConfigs, defaultIntegrationConfig };

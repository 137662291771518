import merge from "lodash.merge";

const defaultIntegrationConfig = {
        name: 'default',
        base: {
          debug: true,
          shop: {
            node: window,
            prefix: 'puma-',
            language: {
              path: 'fitAnalyticsData.language'
            },
            country: {
              path: 'fitAnalyticsData.country'
            },
            shopSessionId: {
              path: 'fitAnalyticsData.shopSessionId'
            }
          },

          product: {
            scope: 'div.testCase.container',
            container: 'div.product',
            currentSerial: {
              node: window,
              path: 'fitAnalyticsData.productId'
            },
            thumbnail: {
              node: window,
              path: 'fitAnalyticsData.thumbnail'
            },
            addToCart: (serial, size) => window.fitAnalyticsData.operations.addToCart(serial, size),
            sizes: {
              node: window,
              items: {
                path: 'fitAnalyticsData.sizes'
              },
              code: {
                path: 'value'
              },
              isAvailable: {
                path: 'isAvailable'
              },
              shouldSelectRecommended: false
            },
            hasManufacturedSizes: true
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: 'button.size-chart-button',
            shouldHide: false
          }
        }
      },
      withUserId = merge({}, defaultIntegrationConfig, {
        name: 'Default with userId',
        base: {
          shop: {
            userId: {
              path: 'fitAnalyticsData.userId'
            }
          }
        }

      }),
      DOMAddTCart = {
        name: 'DOMAddTCart',
        base: {
          debug: true,
          shop: {
            node: window,
            prefix: 'puma-',
            language: {
              path: 'fitAnalyticsData.language'
            },
            country: {
              path: 'fitAnalyticsData.country'
            },
            userId: {
              path: 'fitAnalyticsData.userId'
            },
            shopSessionId: {
              path: 'fitAnalyticsData.shopSessionId'
            }
          },

          product: {
            scope: 'div.testCase.container',
            container: 'div.product',
            currentSerial: {
              node: window,
              path: 'fitAnalyticsData.productId'
            },
            thumbnail: {
              node: window,
              path: 'fitAnalyticsData.thumbnail'
            },
            addToCart: 'button.add-to-cart',
            sizes: {
              elem: '.size-button',
              code: {
                selector: '.',
                prop: 'text'
              },
              isAvailable: {
                selector: '.'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              shouldSelectRecommended: false
            },
            hasManufacturedSizes: true
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: 'button.size-chart-button',
            shouldHide: false
          }
        }
      },
      ATCDisabledConfig = merge({}, defaultIntegrationConfig, {
        name: 'ATCdisabled',
        base: {
          product: {
            addToCart: 'button.add-to-cart',
            isAddToCartDisabled: true
          }
        }
      }),

      dynamicDisabledATC = merge({}, defaultIntegrationConfig, {
        name: 'dynamic disabled ATC',
        base: {
          product: {
            isAddToCartDisabled: () => true
          }
        }
      }),

      atcExperiment = merge({}, defaultIntegrationConfig, {
        name: 'atc experiment',
        base: {
          mockExperiments: {
            'atc_ab': 'control'
          },
          experiments: {
            'atc_ab': {
              effects: {
                atc: null,
                control: {
                  product: {
                    isAddToCartDisabled: true
                  }
                }
              }
            }
          }
        }
      }),

      integrationConfigs = [
        defaultIntegrationConfig, withUserId, DOMAddTCart, ATCDisabledConfig, dynamicDisabledATC,
        atcExperiment
      ];

export { integrationConfigs, defaultIntegrationConfig };

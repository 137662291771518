import './App.scss';
import Home from './components/Home/Home';
import Testing from './components/Testing/Testing';
import Showcase from './components/Showcase/Showcase';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/testing/*" element={<Testing />} />
          <Route exact path="/showcase/*" element={<Showcase />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

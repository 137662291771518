import { Col, Image } from "react-bootstrap";

function Thumbnail({src}) {
  return (
    <>
      <Col lg={4}>
        <Image src = {src} thumbnail />
      </Col>
    </>
  );
}

function ThumbnailImage({src}) {
  return (
    <>
      <Image src = {src} thumbnail />
    </>
  );
}

export { ThumbnailImage };

export default Thumbnail;

import merge from "lodash.merge";

const defaultIntegrationConfig = {
        name: 'Merchant Consent Status',
        base: {
          debug: true,
          scope: '.testCase',
          shop: {
            prefix: 'boss',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userLanguage: {
              selector: 'meta[name="user-language"]',
              attr: 'content'
            },
            blockedLocales: ['fi', 'ru', 'xx'],
            prefetch: false
          },

          product: {
            container: '.row',

            hasManufacturedSizes: true,

            currentSerial: {
              selector: 'meta[name="product-id"]',
              attr: 'content'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              items: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              shouldSelectRecommended: true
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          }
        }
      },

      integrationConfigs = [
        merge({}, defaultIntegrationConfig, {
          name: 'Default without consent status value locator',
          base: {}
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Consent status with value locator',
          base: {
            shop: {
              consent: {
                hasAnalyticsConsent: {
                  process: () => window.fitAnalyticsData?.consent?.hasAnalyticsConsent
                }
              }
            }
          }
        })
      ];

export { defaultIntegrationConfig, integrationConfigs };

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import AddToCartButton from "../AddToCartButton";
import SizeChartButton from "../SizeChart";
import TwoDimensionalSizes from '../TwoDimensionalSizes';
import Thumbnail from "../Thumbnail";
import Title from "../Title";
import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import Cart from "../Cart";
import { setProduct } from './caseProductsSlice';
import { useIntegration } from "./useIntegration";
import { integrationConfigs, defaultIntegrationConfig } from '../../../integration_configs/config5';

const Case5 = (props={}) => {

  window.fitAnalyticsData = props || {};

  const productKey = props.productId,
        dispatch = useDispatch(),
        sizesObject = {};

  (props.sizes || []).forEach((item) => {
    const [fitVal, sizeVal] = (item || '').split(' - ');

    if (fitVal in sizesObject)
      sizesObject[fitVal].push(sizeVal);
    else
      sizesObject[fitVal] = [sizeVal];
  });

  useEffect(() => {
    dispatch(setProduct({id: productKey, product: {}}));
  }, []);

  useIntegration(defaultIntegrationConfig);

  return (<>
    <ConfigPane configs={integrationConfigs} />
    <Container className='testCase'>
      <Row>
        <Col sm={8}></Col>
        <Col sm={4}>
          <Cart placement="end" name="end"></Cart>
        </Col>
      </Row>
      <Row className='product'>
        <Thumbnail src = {props.thumbnail} />
        <Col>
          <Meta {... props}/>
          <Title title={props.title} />
          <p>{props.description}</p>

          <TwoDimensionalSizes sizeData={props.sizes} selectedSecondarySize='29' separator='/'/>
          <SizeChartButton />
          <AddToCartButton
            productId = {props.productId}
            thumbnail = {props.thumbnail}
          />
        </Col>
      </Row>
    </Container>
  </>);
};

export { Case5 };

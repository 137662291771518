import merge from "lodash.merge";
const experiments = {
  "button_engagement": {
    effects: {
      "button_1": {
        button: {
          style: {
            button: {
              'background-color': "#f00"
            }
          }
        }
      },
      "button_2": {
        button: {
          style: {
            button: {
              'background-color': "#00f"
            }
          }
        }
      }
    }
  }
};

const defaultIntegrationConfig = {
        name: 'Default',
        base: {
          debug: true,
          shop: {
            prefix: 'alioop',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userLanguage: {
              selector: 'meta[name="user-language"]',
              attr: 'content'
            },
            userId: {
              selector: 'meta[name="user-id"]',
              attr: 'content'
            },
            blockedLocales: ['fi', 'ru', 'xx'],
            prefetch: false
          },

          product: {
            scope: '.testCase',
            container: '.product',

            hasManufacturedSizes: true,

            currentSerial: {
              selector: 'meta[name="product-id"]',
              attr: 'content'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',
            addToCartElement: 'button.add-to-cart',

            isAddToCartReady: {
              selector: '.add-to-cart',
              is: ':not([disabled])'
            },

            sizes: {
              elem: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not(.out-of-stock)'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              shouldSelectRecommended: true
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          },

          experiments
        }
      },
      objectBasedIntegration = {
        name: 'Object-Based Integration',
        base: {
          debug: true,
          shop: {
            node: window,
            prefix: 'alioop',
            language: {
              path: 'fitAnalyticsData.language'
            },
            country: {
              path: 'fitAnalyticsData.country'
            },
            userLanguage: {
              path: 'fitAnalyticsData.userLanguage'
            }
          },

          product: {
            scope: 'div.testCase.container',
            container: 'div.product',
            currentSerial: {
              node: window,
              path: 'fitAnalyticsData.productId'
            },
            thumbnail: {
              node: window,
              path: 'fitAnalyticsData.thumbnail'
            },
            addToCart: (serial, size) => window.fitAnalyticsData.operations.addToCart(serial, size),
            addToCartElement: 'button.add-to-cart',
            sizes: {
              node: window,
              items: {
                path: 'fitAnalyticsData.objectSizes'
              },
              code: {
                path: 'value'
              },
              isAvailable: {
                path: 'isAvailable'
              }
            },
            currentSize: {
              node: window,
              path: 'fitAnalyticsData.operations.getCurrentSize'
            },
            hasManufacturedSizes: true
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: 'button.size-chart-button',
            shouldHide: true
          },
          mockExperiments: {
            button_engagement: 'button_1'
          },
          experiments
        }
      },
      integrationConfigs = [
        defaultIntegrationConfig,
        objectBasedIntegration,
        merge({}, defaultIntegrationConfig, {
          name: 'Config 1',
          base: {
            product: {
              addToCart: ()=>{}
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Config 2',
          base: {
            experiments: null
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'ATC click callback function',
          base: {
            product: {
              addToCart: (serial, size, details, scope) => {
                const sizeElement = document.querySelector(`.size-button[value=${size}]`);
                const atcButton = document.querySelector('.add-to-cart');

                sizeElement?.click();
                atcButton?.click();
              }
            },

            mockExperiments: {
              button_engagement: 'button_1'
            },
            experiments
          }
        }),
        merge({}, objectBasedIntegration, {
          name: 'Object int - atc as function',
          base: {
            product: {
              addToCart: (serial, size, details, scope) => {
                const sizeElement = document.querySelector(`.size-button[value=${size}]`);
                const atcButton = document.querySelector('.add-to-cart');

                sizeElement?.click();
                atcButton?.click();
              }
            }
          }
        }),
        merge({}, objectBasedIntegration, {
          name: 'Object int - atc as empty function',
          base: {
            product: {
              addToCart: (serial, size, details, scope) => {}
            }
          }
        })
      ];

export { integrationConfigs, defaultIntegrationConfig };

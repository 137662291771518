import { Container, Row, Col } from "react-bootstrap";
import AddToCartButton from "../AddToCartButton";
import Sizes from "../Sizes";
import Thumbnail from "../Thumbnail";
import SizeChart from "../SizeChart";
import Title from "../Title";
import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import Cart from "../Cart";
import { useIntegration } from "./useIntegration";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setProduct } from "./caseProductsSlice";
import { addItemToCart, addAsyncItemToCart, getAsync, getCartItems } from "../cartSlice";
import { integrationConfigs, defaultIntegrationConfig } from '../../../integration_configs/config25';

function Case25(props = {}) {
  const dispatch = useDispatch(),
        productKey = props.productId,
        isAsync = useSelector(getAsync),
        cartItems = useSelector(getCartItems);


  useIntegration(defaultIntegrationConfig);

  useEffect(() => {
    dispatch(setProduct({id: productKey, product: {}}));

    const script = document.createElement('script');
    script.src = "https://osiris.fitanalytics.com/pdp-checker.osiris.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  window.fitAnalyticsData = {
    shopLanguage: props.language, 
    shopCountry: props.country, 
    mainImageLink: props.thumbnail, 
    userId: props.userId, 
    currentItemSubgroupId: props.productId, 
    allItemSubgroupIds: ['xxx', 'xxx', 'xxx'], 
    shopSessionId: props.shopSessionId, 
    sizes: props.sizes,
    operations: {
      getSizeChartLink: () => {
        let sizeChartEl = document.querySelector('.size-chart-button');
        return sizeChartEl || '';
      },
      getAddToCartBtn: () => {
        let addToCartEl = document.querySelector('.add-to-cart');
        return addToCartEl;
      },
      getCurrentSize: () => {
        let currentSizeEl = document.querySelector('.size-button.selected[value]');
        return currentSizeEl || 'S';
      },
      selectSize: (serial, size) => {
        console.log(serial, size);
      },
      addToCart: (serial, size) => {
        const productObj = {
          productId: serial.split('-').pop(),
          thumbnail: props.thumbnail,
          size: size,
          showCartOnAdd: true
        };
        dispatch(isAsync ? addAsyncItemToCart(productObj) : addItemToCart(productObj));
      },
      getCartItems: () => cartItems
    }
  };

  return (<>
    <ConfigPane configs={integrationConfigs}/>
    <Container className='testCase'>
      <Row className='add-to-cart'>
        <Col sm={8}></Col>
        <Col sm={4}>
          <Cart placement="end" name="end"></Cart>
        </Col>
      </Row>
      <Row className='product'>
        <Thumbnail src = {props.thumbnail}/>
        <Col>
          <Meta {...props}/>
          <Title title={props.title}/>
          <p>{props.description}</p>
          <Sizes sizes={props.sizes.map(size => size.value)}
            availability={4/6}
          />
          <SizeChart />
          <AddToCartButton
            productId= {props.productId}
            thumbnail = {props.thumbnail}/>
        </Col>
      </Row>
    </Container>
  </>);
};

export { Case25 };

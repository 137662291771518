import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SizeChart from "../SizeChart";
import Sizes from "../Sizes";
import Thumbnail from "../Thumbnail";
import Title from "../Title";
import Meta from "../Meta";
import ConfigPane from "./ConfigPane";
import Accordion from 'react-bootstrap/Accordion';
import { integrationConfigs, defaultIntegrationConfig } from "../../../integration_configs/config18";
import { useIntegration } from "./useIntegration";

function Case18(props = {}) {
  useIntegration(defaultIntegrationConfig);
  useEffect(() => {
    document.cookie = 'ssid=ssidCookie';
  }, []);

  return (<>
    <ConfigPane configs={integrationConfigs} />
    <Container className='testCase'>
      <Row>
        <Thumbnail src = {props.thumbnail}/>
        <Col>
          <Meta {... props}/>
          <Title title={props.title}/>
          <p>{props.description}</p>
          <div className="mainSizeChart">
            <SizeChart />
          </div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Select Size:</Accordion.Header>
              <Accordion.Body>
                <Sizes sizes={props.sizes} availability={4/5}/>
                <SizeChart />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </>
  );
}

export { Case18 };

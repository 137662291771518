function Meta(props = {}) {
  return (
    <div className="meta-data">
      <meta name="product-id" content={props.productId}/>
      <meta name="style-id" content={props.styleId}/>
      <meta name="color-id" content={props.colorId}/>
      <meta name="country" content={props.country}/>
      <meta name="language" content={props.language}/>
      <meta name="user-language" content={props.userLanguage}/>
      <meta name="user-id" content={props.userId}/>
      <meta name="shop-session-id" content={props.shopSessionId}/>
    </div>
  );
}

function PlpMeta(props = {}) {
  return (
    <div className="plp-meta-data">
      <meta name="gender" content={props.gender}/>
      <meta name="category" content={props.category}/>
    </div>
  );
}

export default Meta;
export {PlpMeta};

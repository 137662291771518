import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  startIntegration,
  stopIntegration,
  clearIntegration
} from "../../../integration";
import {
  dismissIntegration,
  launchIntegration,
  getCaseState
} from "./CaseSlice";
import { getPageConfig } from "../PageConfigSlice";

function useIntegration(defaultIntegrationConfig) {
  const pageConfig = useSelector(getPageConfig);
  const dispatch = useDispatch();
  const caseState = useSelector(getCaseState);

  const triggerIntegration = useCallback(
    (config) => {
      if (caseState.isIntegrationLaunched) return;

      dispatch(launchIntegration());
      const integrationConfig = {
        ...config,
        base: {
          ...config.base,
          highlight: config.base.highlight ?? pageConfig.isHighlightActive
        },
        shouldMountIntegrationByDefault:
          pageConfig.shouldMountIntegrationByDefault
      };

      startIntegration(integrationConfig);
    },
    [
      pageConfig.isDefaultIntegration,
      pageConfig.shouldMountIntegrationByDefault,
      caseState.isIntegrationLaunched
    ]
  );

  useEffect(() => {
    if (pageConfig.isDefaultIntegration && defaultIntegrationConfig) {
      triggerIntegration(defaultIntegrationConfig);
    }

    return () => {
      dispatch(dismissIntegration());
      stopIntegration();
      clearIntegration();
    };
  }, [pageConfig.isDefaultIntegration, defaultIntegrationConfig]);

  return { triggerIntegration };
}

export { useIntegration };

import merge from "lodash.merge";

const defaultIntegrationConfig = {
        name: 'Default',
        base: {
          debug: true,
          shop: {
            prefix: 'alioop',
            node: '.testCase',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userId: {
              selector: 'meta[name="user-id"]',
              attr: 'content'
            }
          },
          product: {
            scope: '.testCase',
            container: '.row',

            hasManufacturedSizes: true,

            currentSerial: {
              selector: 'meta[name="product-id"]',
              attr: 'content'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              elem: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              shouldSelectRecommended: true
            },
            sizingSystem: {
              selector: '.sizing-systems button.selected',
              attr: 'value'
            },
            sizingSystemMap: {
              'BE': '',
              'DE': '',
              'ES': '',
              'IT': '',
              'FR': '',// the FR,DE,ES,IT,and FR items don't have shopSizingSystem in the database; see https://github.com/UPcload/feed-configs/blob/master/configs/maje-fr/shop.yaml
              'MAJE_RE': 'GB',
              'IE': 'GB',
              'CH': 'GB',
              'UK': 'GB',
              'US': 'US'
            },
            changeIndicator: ['serial', 'sizingSystem', 'country']
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          }
        }
      },

      /**
       * Product suggestions should not show up when the locales are defined in the integration config
       * but the feature is disabled in the shop config
       */
      blockPS = merge({}, defaultIntegrationConfig, {
        name: 'Failing attempt to show Product Suggestions for en_US',
        base: {
          shop: {
            productSuggestionsLocales: ['en_US']
          }
        }
      }),

      integrationConfigs = [ defaultIntegrationConfig, blockPS ];

export { integrationConfigs, defaultIntegrationConfig };

import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AddToCartButton from "../AddToCartButton";
import Sizes from "../Sizes";
import Thumbnail from "../Thumbnail";
import Title from "../Title";
import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import { Swatches } from "../Swatches";
import SizeChartButton from "../SizeChart";
import { integrationConfigs, defaultIntegrationConfig } from '../../../integration_configs/config7';
import { useIntegration } from "./useIntegration";


function Case7(props = {}) {
  useIntegration(defaultIntegrationConfig);

  useEffect(() => {
    document.cookie = 'ssid_fjallraven=ssidCookie';
  }, []);

  return (<>
    <ConfigPane configs={integrationConfigs} />
    <Container className='testCase'>
      <Row>
        <Thumbnail src = {props.thumbnail} />
        <Col>
          <Meta {... props}/>
          <Title title={props.title} />
          <p>{props.description}</p>
          <Swatches colors={props.colorIds} />
          <Sizes sizes={props.sizes}
            availability={4/5}
          />
          <SizeChartButton />
          <AddToCartButton />
        </Col>
      </Row>
    </Container>
  </>);
}

export {Case7};

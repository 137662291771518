import React from "react";
import { useLocation } from "react-router-dom";
import NotFound from "../NotFound";
import * as Cases from './cases';
import { useSelector } from "react-redux";
import { getCases } from "../../data/caseSlice";

function TestCase() {
  const location = useLocation();
  const toFirstLetterUppercase = (str) => {
    if (typeof str === 'string') {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return '';
    }
  };
  let caseId = (location.pathname.match(/testing\/(\w+)\/?/i) || ['']).pop();
  caseId = toFirstLetterUppercase(caseId);
  const Case = Cases[caseId];
  const testCases = useSelector(getCases);
  const caseProps = testCases.filter((testCase)=>new RegExp(`^${caseId}$`, 'i').test(testCase.caseId)).pop();

  if (Case) {
    return (
      <>
        <Case {... caseProps}/>
      </>
    );
  } else {
    return (
      <NotFound />
    );
  }
}

export default TestCase;

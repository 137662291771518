
const defaultIntegrationConfig = {
        name: 'defaultConfig',
        base: {
          shop: {
            prefix: 'speedo_au-',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userId: {
              selector: 'meta[name="user-id"]',
              attr: 'content'
            }
          },

          product: {
            scope: '.testCase',
            container: '.row',

            hasManufacturedSizes: true,

            currentSerial: {
              selector: 'meta[name="product-id"]',
              attr: 'content'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              elem: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              secondary: {
                elem: 'button.fit-size',
                code: {
                  selector: '.',
                  prop: 'value'
                },
                isAvailable: {
                  selector: '.',
                  is: ':not([disabled])'
                },
                isSelected: {
                  selector: '.',
                  is: '.selected'
                },
                delimiter: ' - '
              },
              shouldSelectRecommended: true
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after',
            style: {
              'button': {
                'width': 'fit-content'
              }
            }
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          }
        }
      },

      objectIntegrationConfig = {
        name: 'objectConfig',
        base: {
          shop: {
            node: window,
            prefix: 'speedo_au-',
            language: {
              path: 'fitAnalyticsData.language'
            },
            country: {
              path: 'fitAnalyticsData.country'
            },
            userId: {
              path: 'fitAnalyticsData.userId'
            },
            shopSessionId: {
              path: 'fitAnalyticsData.shopSessionId'
            }
          },

          product: {
            scope: '.testCase',
            container: '.row',

            hasManufacturedSizes: true,

            currentSerial: {
              node: window,
              path: 'fitAnalyticsData.productId'
            },

            thumbnail: {
              node: window,
              path: 'fitAnalyticsData.thumbnail'
            },

            addToCart: null,

            sizes: {
              node: window,
              items: {
                path: 'fitAnalyticsData.sizes'
              },
              code: {
                path: 'value'
              },
              isAvailable: {
                path: 'isAvailable'
              },
              shouldSelectRecommended: false
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after',
            style: {
              'button': {
                'width': 'fit-content'
              }
            }
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          }
        }
      },

      integrationConfigs = [defaultIntegrationConfig, objectIntegrationConfig];

export { defaultIntegrationConfig, integrationConfigs };

import { createSlice } from '@reduxjs/toolkit';

export const caseProductsSlice = createSlice({
  name: 'caseProducts',
  initialState: {},
  reducers: {
    setProduct: (state, action) => {
      let key = action.payload.id || 'default';
      if (!state[key]) {
        state[key] = action.payload.product;
      }
    },
    setSelectedColor: (state, action) => {
      let key = action.payload.id || 'default';
      state[key] = state[key] || {};
      state[key].selectedColor = action.payload.value;
    },
    setSelectedSize: (state, action) => {
      let key = action.payload.id || 'default';
      state[key] = state[key] || {};
      state[key].selectedSize = action.payload.value;
      state[key].isSizeAvailable = action.payload.isAvailable;
    },
    setSelectedSecondarySize: (state, action) => {
      let key = action.payload.id || 'default';

      state[key] = state[key] || {};
      state[key].selectedSecondarySize = action.payload.value;
    },
    set2DSizesSeparator: (state, action) => {
      let key = action.payload.id || 'default';
      state[key] = state[key] || {};
      state[key].sizesSeparator = action.payload.value;
    }
  }
});

export const {
  setProduct,
  setSelectedColor,
  setSelectedSize,
  setSelectedSecondarySize,
  set2DSizesSeparator
} = caseProductsSlice.actions;

export const getCaseProducts = (state) => state.caseProducts;

export default caseProductsSlice.reducer;

import { Badge, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function TestingTile(props) {
  const navigate = useNavigate();
  const onTileClick = () => {
    navigate(`./${props.caseId}`);
  };
  let bodyClassName = `${props.thumbnail ? '' : 'mt-5'}`;
  return (
    <>
      <Card onClick={onTileClick} style={{cursor: 'pointer'}}>
        <Card.Img variant='top' src={props.thumbnail}/>
        <Card.ImgOverlay>
          <Badge>{props.caseId}</Badge>
        </Card.ImgOverlay>
        <Card.Body className={bodyClassName}>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>{props.description}</Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

export default TestingTile;

import merge from "lodash.merge";

const defaultIntegrationConfig = {
        name: 'Default',
        base: {
          debug: true,
          shop: {
            prefix: 'alioop',
            country: () => 'US',
            language: () => 'en',
            userLanguage: {
              selector: 'meta[name="user-language"]',
              attr: 'content'
            },
            userId: {
              selector: 'meta[name="user-id"]',
              attr: 'content'
            },
            shopSessionId: {
              cookie: 'ssid'
            }
          },

          scope: '.testCase',
          product: {
            container: '.row',

            hasManufacturedSizes: true,

            currentSerial: {
              selector: 'meta[name="product-id"]',
              attr: 'content'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              items: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              shouldSelectRecommended: true
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          }
        }
      },

      noShopSessionId = merge({}, defaultIntegrationConfig, {
        name: 'No Shop Session ID',
        base: {
          shop: {
            shopSessionId: null
          }
        }
      }),

      merchantConsent = merge({}, defaultIntegrationConfig, {
        name: 'Merchant Consent',
        base: {
          shop: {
            consent: {
              hasAnalyticsConsent: {
                process: () => window.fitAnalyticsData?.consent?.hasAnalyticsConsent
              }
            }
          }
        }
      }),

      integrationConfigs = [
        defaultIntegrationConfig, noShopSessionId, merchantConsent
      ];

export { defaultIntegrationConfig, integrationConfigs };

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCaseProducts, setProduct } from "./cases/caseProductsSlice";
import { Col, Row } from "react-bootstrap";
import { Swatches } from "./Swatches";
import Sizes from "./Sizes";
import AddToCartButton from "./AddToCartButton";
import SizeChartButton from "./SizeChart";
import {ThumbnailImage as Thumbnail} from "./Thumbnail";
import Title from "./Title";
import Meta from "./Meta";
import { FitFinder } from "./FitFinder";


export const OutfitProduct = (props={}) => {
  const dispatch = useDispatch(),
        productKey = props.styleId,
        caseProducts = useSelector(getCaseProducts);

  useEffect(() => {
    dispatch(setProduct({id: productKey, product: {}}));
  }, []);

  const availability = 4/5;
  const productId = props.styleId + "" + caseProducts[props.styleId]?.selectedColor;

  return (
    <>
      <Row
        className='mb-4 p-3 justify-content-md-center outfit-product'
        style={{border: '1px solid gray'}}
        sm={2} xs={1}>
        <Col md={4}>
          <Thumbnail src={props.thumbnail} />
        </Col>
        <Col md={4} >
          <Meta {... props}/>
          <Title title={props.title}/>
          <Swatches productKey={productKey} colors={props.colorIds} />
          <Sizes
            productKey={productKey}
            sizes={props.sizes}
            availability={4/5}
          />
          <FitFinder externalId={productId} sizes={props.sizes} sizeAvailability={availability}/>
          <SizeChartButton />
          <AddToCartButton productKey={productKey}/>
        </Col>
      </Row>
    </>
  );
};

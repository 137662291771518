import merge from "lodash.merge";

const defaultIntegrationConfig = {
        name: 'Default',
        base: {
          debug: true,
          scope: '.testCase',
          shop: {
            prefix: 'boss',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userLanguage: {
              selector: 'meta[name="user-language"]',
              attr: 'content'
            },
            blockedLocales: ['fi', 'ru', 'xx'],
            prefetch: false
          },

          product: {
            container: '.row',

            hasManufacturedSizes: true,

            currentSerial: {
              selector: 'meta[name="product-id"]',
              attr: 'content'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              items: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              shouldSelectRecommended: true
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          }
        }
      },

      integrationConfigs = [
        defaultIntegrationConfig,
        merge({}, defaultIntegrationConfig, {
          name: 'Default with userId',
          base: {
            shop: {
              userId: {
                selector: 'meta[name="user-id"]',
                attr: 'content'
              }
            }
          }
        }),
        {
          name: 'Config 1',
          base: {
            shop: {
              prefix: 'widgetpreview'
            },
            product: {}
          }
        },
        {
          name: 'Config 2',
          base: {}
        },
        {
          name: 'Config 3',
          base: {
            shop: {
              prefix: 'widgetpreview',
              isReady: function () {
                return Boolean(window.fitaReady);
              }
            },
            product: {}
          }
        },
        {
          name: 'Config 4',
          base: {
            shop: {
              prefix: 'widgetpreview',
              shopSessionId: {
                cookie: 'ssid'
              }
            },
            product: {}
          }
        },
        {
          name: 'Config 5',
          base: {
            shop: {
              prefix: 'widgetpreview',
              node: () => window,
              country: {
                path: 'country'
              }
            },
            product: {}
          }
        },
        merge({}, defaultIntegrationConfig, {
          name: 'Config 6',
          base: {
            button: {
              anchorPlacement: 'append',
              text: {
                'en': ['What\'s your size?', 'Size {{size}} will fit you best']
              },
              style: {
                'image': {
                  'width': '114px',
                  'height': '22px',
                  'cursor': 'pointer',
                  'display': 'inline',
                  'margin-left': '10px'
                },
                'text': {
                  'margin': '0.1em',
                  'margin-left': '0px',
                  'font-size': '14px',
                  'color': '#5a5649',
                  'line-height': '23px'
                }
              },
              textAttrs: {
                'onmouseover': 'this.style.color="blue"'
              }
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Config 7',
          base: {
            button: {
              disableDefaultStyles: true,
              style: {
                'image': {
                  'display': 'none'
                },
                'text': {
                  'margin': '0.1em',
                  'margin-left': '0px',
                  'font-size': '14px',
                  'color': '#5a5649',
                  'line-height': '23px',
                  'cursor': 'pointer'
                },
                'ariaCSS': {
                  'outline': '2px #000 solid',
                  'outline-offset': '3px'
                }
              }
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'blockedLocale config',
          base: {
            shop: {
              country: () => 'US',
              language: () => 'de',
              blockedLocales: ['de_US']
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Config 8',
          base: {
            globalEvents: {
              'product_load': function (serial) { window._fitAnalytics.integration.metricChronicle.addRecord({name: 'product_load',value: 'globalEvent', serial});},
              'product_error': function (serial) { window._fitAnalytics.integration.metricChronicle.addRecord({name: 'product_error',value: 'globalEvent', serial});},
              'add_to_cart': function (serial, size, details) { window._fitAnalytics.integration.metricChronicle.addRecord({name: 'add_to_cart',value: 'globalEvent', serial, size, details});},
              'size_recommended': function (serial, size, details) { window._fitAnalytics.integration.metricChronicle.addRecord({name: 'size_recommended',value: 'globalEvent', serial, size, details});},
              'fit_finder_open': function (serial) { window._fitAnalytics.integration.metricChronicle.addRecord({name: 'fit_finder_open',value: 'globalEvent', serial});},
              'fit_finder_close': function (serial, size, details) { window._fitAnalytics.integration.metricChronicle.addRecord({name: 'fit_finder_close',value: 'globalEvent', serial, size, details});},
              'metric_event': function (eventName, eventValue, details) { window._fitAnalytics.integration.metricChronicle.addRecord({name: 'metric_event',value: 'globalEvent', eventName, eventValue, details});}
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Config 9',
          base: {
            product: {
              currentSerial: {
                selector: 'meta[name="product-id"]',
                attr: 'content',
                process: serial => serial += '_unsupported'
              }
            },
            globalEvents: {
              'product_error': function (serial) { window._fitAnalytics.integration.metricChronicle.addRecord({name: 'product_error',value: 'globalEvent', serial});}
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'No serial collected',
          base: {
            product: {
              currentSerial: {
                selector: 'meta[name="product-idd"]',
                attr: 'contentt'
              }
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'unsuccessful language/country locators',
          base: {
            shop: {
              country: {
                selector: 'meta[name="countryy"]',
                attr: 'contentt'
              },
              language: {
                selector: 'meta[name="languagee"]',
                attr: 'contentt'
              }
            },
            product: {}
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Default w/o debug',
          base: {
            debug: false
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Multiple FF Buttons',
          base: {
            product: {
              sizes: {
                shouldSelectRecommended: false
              }
            },
            extraButtons: [
              {
                key: 'secondary',
                node: 'body',
                anchor: '.FF-Anchor',
                anchorPlacement: 'append'
              },
              {
                key: 'tertiary',
                anchor: '.sizes',
                anchorPlacement: 'before'
              }
            ],
            sizeChartButton: {
              shouldHide: false
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Legacy Variant Scope',
          base: {
            scope: null,
            product: {
              scope: '.testCase'
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Highlight feature',
          base: {
            highlight: true,
            scope: null,
            product: {
              scope: '.testCase'
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Highlight feature with debug as false',
          base: {
            debug: false,
            highlight: true,
            scope: null,
            product: {
              scope: '.testCase'
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Show Out Of Stock Text on button',
          base: {
            button: {
              shouldShowOutOfStockText: true,
              text: {
                'en': ['What\'s your size?', 'Size {{size}} will fit you best', 'Your size {{size}} is not available']
              }
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Show Out Of Scale Text on button',
          base: {
            button: {
              shouldShowOutOfScaleText: true,
              text: {
                'en': ['What\'s your size?', 'Size {{size}} will fit you best', 'Your size {{size}} is not available', 'No matching size']
              }
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Flex Containers',
          base: {
            button: {},
            extraButtons: [
              {
                key: 'primary',
                anchor: '.firstRow',
                anchorPlacement: 'append',
                order: 2,
                imageSrc: '//integrations.fitanalytics.com/assets/button/ff-icon-black.svg',
                style: {
                  image: {
                    width: 'fit-content'
                  }
                }
              },
              {
                key: 'secondary',
                anchor: '.firstRow',
                anchorPlacement: 'append',
                order: 1
              },
              {
                key: 'tertiary',
                anchor: '.secondRow',
                anchorPlacement: 'append',
                order: 1,
                style: {
                  text: {
                    display: 'none'
                  }
                }
              }
            ],
            containers: [
              {
                key: 'primaryContainer',
                anchor: '.sizes',
                style: {
                  border: '2px solid red',
                  margin: '5px 0'
                }
              },
              {
                key: 'secondRow',
                anchor: '.primaryContainer',
                anchorPlacement: 'append',
                order: 2,
                isRow: true,
                style: {
                  margin: '3px',
                  padding: '2px',
                  border: '2px solid green',
                  gap: '5px'
                }
              },
              {
                key: 'firstRow',
                anchor: '.primaryContainer',
                anchorPlacement: 'append',
                order: 1,
                isRow: true,
                style: {
                  margin: '3px',
                  padding: '2px',
                  border: '2px solid blue',
                  gap: '5px',
                  'justify-content': 'space-evenly'
                }
              }
            ]
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'styled FF container',
          base: {
            containers: [
              {
                key: 'styledContainer',
                anchor: '.sizes',
                style: {
                  'border': '1px solid black',
                  'border-radius': '5px',
                  'margin-top': '5px',
                  'margin-bottom': '5px'
                }
              },
              {
                key: 'firstRow',
                anchor: '.styledContainer',
                anchorPlacement: 'append',
                order: 1,
                isRow: true
              }
            ],
            button: {},
            extraButtons: [
              {
                key: 'primary',
                anchor: '.firstRow',
                anchorPlacement: 'append',
                order: 2
              },
              {
                key: 'secondary',
                anchor: '.firstRow',
                anchorPlacement: 'append',
                order: 1
              }
            ]
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Containers for non supported product',
          base: {
            button: {},
            extraButtons: [
              {
                key: 'primary',
                anchor: '.firstRow',
                anchorPlacement: 'append',
                order: 2,
                style: {
                  button: {
                    background: 'blue'
                  }
                }
              },
              {
                key: 'secondary',
                anchor: '.firstRow',
                anchorPlacement: 'append',
                order: 1
              },
              {
                key: 'tertiary',
                anchor: '.secondRow',
                anchorPlacement: 'append',
                order: 1,
                style: {
                  text: {
                    display: 'none'
                  }
                }
              }
            ],
            containers: [
              {
                key: 'primaryContainer',
                anchor: '.sizes'
              },
              {
                key: 'secondRow',
                anchor: '.primaryContainer',
                anchorPlacement: 'append',
                order: 2,
                isRow: true
              },
              {
                key: 'firstRow',
                anchor: '.primaryContainer',
                anchorPlacement: 'append',
                order: 1,
                isRow: true
              }
            ],
            product: {
              currentSerial: {
                selector: 'meta[name="product-id"]',
                attr: 'content',
                process: serial => serial += '_unsupported'
              }
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Block Product Suggestions',
          base: {
            shop: {
              productSuggestionsLocales: []
            }
          }
        }),
        merge({}, defaultIntegrationConfig, {
          name: 'Allow Product Suggestions for de_DE',
          base: {
            shop: {
              productSuggestionsLocales: ["de_DE"]
            }
          }
        })
      ];

export { defaultIntegrationConfig, integrationConfigs };

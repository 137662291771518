import {Button, Table, Modal} from "react-bootstrap";
import { useState } from "react";

function SizeChartModal(props) {

  return (
    <Modal
      className = 'size-chart'
      {...props}
      size="lg"
      aria-labelledby="Size Chart"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="size_chart">
        Size Chart
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="FF-Anchor">
        </div>
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th colSpan="2">Women</th>
              <th colSpan="2">Men</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>INT</th>
              <th>EU</th>
              <th>INT</th>
              <th>EU</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>XS</td>
              <td>34</td>
              <td>XS</td>
              <td>44</td>
            </tr>
            <tr>
              <td>S</td>
              <td>36</td>
              <td>S</td>
              <td>46</td>
            </tr>
            <tr>
              <td>M</td>
              <td>38</td>
              <td>M</td>
              <td>48</td>
            </tr>
            <tr>
              <td>L</td>
              <td>40</td>
              <td>L</td>
              <td>50</td>
            </tr>
            <tr>
              <td>XL</td>
              <td>42</td>
              <td>XL</td>
              <td>52</td>
            </tr>
            <tr>
              <td>XXL</td>
              <td>44</td>
              <td>XXL</td>
              <td>54</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}

function SizeChartButton() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div>
        <Button
          className='size-chart-button'
          style= {
            {
              color: 'grey',
              width: 'fit-content',
              padding: '0px',
              marginBottom: '10px'
            }
          }
          variant="link"
          onClick={() => setModalShow(true)}>
          Size Chart
        </Button>
      </div>
      <SizeChartModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default SizeChartButton;

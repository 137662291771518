import { useEffect } from "react";
import {Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setSelectedSize } from "./cases/caseProductsSlice";
import { useCaseProduct } from "./cases/useCaseProducts";

function Sizes({productKey, sizes = [], availability = 1, disableButton = true}) {
  const dispatch = useDispatch(),
        {product} = useCaseProduct(productKey),
        selectedSize = product.selectedSize;

  useEffect(() => {
    return () => setSize(null, null);
  }, []);

  const onSizeClick = (ev) => {
    setSize(ev.currentTarget.value, !/out-of-stock/.test(ev.currentTarget.className));
  };

  const setSize = (size, isAvailable) => {
    let payload = {
      id: productKey,
      value: size,
      isAvailable: isAvailable
    };
    dispatch(setSelectedSize(payload));
  };
  return (
    <div className = 'sizes mb-3'>
      <h6>Sizes: </h6>
      <div style={{display: 'flex'}} >
        {
          sizes.map((size, index, array) => {
            let isAvailable = index/(array.length-1) <= availability,
                isSelected = selectedSize === size,
                className = `${isSelected ? 'selected' : ''}
                             ${!isAvailable ? 'out-of-stock' : ''}
                             me-2 mb-2 
                            `.replace(/(\s)+/g, ' ').trim();
            return <div className='sizeOption' key={size}>
              <Button className={'size-button' + ' ' + `${className}`}
                value={size}
                disabled={!isAvailable && disableButton}
                title={size}
                variant={isSelected ? 'secondary':'outline-secondary'}
                onClick={onSizeClick}
              >
                <span>{size}</span>
              </Button>
            </div>;
          })
        }
      </div>
    </div>
  );
}

export default Sizes;

const defaultIntegrationConfig = {
        name: 'Default',
        base: {
          debug: true,
          shop: {
            prefix: 'boss',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userId: {
              selector: 'meta[name="user-id"]',
              attr: 'content'
            }
          }
        },
        variants: [
          // Main product config :
          {
            key: 'primary',
            variantType: 'primary',
            product: {
              scope: '.testCasePrimary',
              container: '.row',
              hasManufacturedSizes: true,
              colorSeparator: '',
              styleId: {
                selector: 'meta[name="style-id"]',
                attr: 'content'
              },

              currentColorId: {
                selector: '.colors .colorId.selected',
                attr: 'value'
              },

              colorIds: {
                selector: '.colors .colorId',
                attr: 'value'
              },
              thumbnail: {
                selector: '.img-thumbnail',
                attr: 'src'
              },
              addToCart: 'button.add-to-cart',
              sizes: {
                elem: '.sizes button',
                code: {
                  selector: '.',
                  prop: 'value'
                },
                isAvailable: {
                  selector: '.',
                  is: ':not([disabled])'
                },
                isSelected: {
                  selector: '.',
                  is: '.selected'
                },
                shouldSelectRecommended: true
              }
            },
            button: {
              anchor: '.testCasePrimary .sizes',
              anchorPlacement: 'after'
            },

            sizeChartButton: {
              elem: '.testCasePrimary .size-chart-button',
              shouldHide: true
            }
          },
          // Outfit config :
          {
            key: 'mpp',
            variantType: 'mpp',
            product: {
              scope: '.outfit',
              container: '.outfit-product',
              hasManufacturedSizes: true,
              colorSeparator: '',
              styleId: {
                selector: 'meta[name="style-id"]',
                attr: 'content'
              },
              currentColorId: {
                selector: '.colors .colorId.selected',
                attr: 'value'
              },
              colorIds: {
                selector: '.colors .colorId',
                attr: 'value'
              },
              thumbnail: {
                selector: '.img-thumbnail',
                attr: 'src'
              },
              addToCart: 'button.add-to-cart',
              sizes: {
                elem: '.sizes button',
                code: {
                  selector: '.',
                  prop: 'value'
                },
                isAvailable: {
                  selector: '.',
                  is: ':not([disabled])'
                },
                isSelected: {
                  selector: '.',
                  is: '.selected'
                },
                shouldSelectRecommended: true
              }
            },
            button: {
              anchor: '.outfit-product .sizes',
              anchorPlacement: 'after'
            },

            sizeChartButton: {
              elem: '.outfit-product .size-chart-button',
              shouldHide: true
            }
          },
          // Listing Products config :
          {
            key: 'quickview',
            variantType: 'quickview',
            product: {
              // scope: '.plp',
              container: '.quickview .quickview-product',
              hasManufacturedSizes: true,
              colorSeparator: '',
              styleId: {
                selector: 'meta[name="style-id"]',
                attr: 'content'
              },
              currentColorId: {
                selector: '.colors .colorId.selected',
                attr: 'value'
              },
              colorIds: {
                selector: '.colors .colorId',
                attr: 'value'
              },
              thumbnail: {
                selector: '.img-thumbnail',
                attr: 'src'
              },
              addToCart: 'button.add-to-cart',
              sizes: {
                elem: '.sizes button',
                code: {
                  selector: '.',
                  prop: 'value'
                },
                isAvailable: {
                  selector: '.',
                  is: ':not([disabled])'
                },
                isSelected: {
                  selector: '.',
                  is: '.selected'
                },
                shouldSelectRecommended: true
              }
            },
            button: {
              anchor: '.quickview-product .sizes',
              anchorPlacement: 'after'
            },

            sizeChartButton: {
              elem: '.quickview-product .size-chart-button',
              shouldHide: true
            }
          }
        ]
      },
      integrationConfigs = [ defaultIntegrationConfig ];

export { integrationConfigs, defaultIntegrationConfig };

import merge from "lodash.merge";

const defaultIntegrationConfig = {
        name: 'Default',
        base: {
          debug: true,
          node: '.testCase',
          shop: {
            prefix: 'boss',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userId: {
              selector: 'meta[name="user-id"]',
              attr: 'content'
            }
          },
          scope: '.outfit',
          product: {
            container: '.outfit-product',

            hasManufacturedSizes: true,

            colorSeparator: '',
            styleId: {
              selector: 'meta[name="style-id"]',
              attr: 'content'
            },

            currentColorId: {
              selector: '.colors .colorId.selected',
              attr: 'value'
            },

            colorIds: {
              selector: '.colors .colorId',
              attr: 'value'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              items: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              shouldSelectRecommended: true
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: '.size-chart-button',
            shouldHide: true
          }
        }
      },
      blockProductSuggestions = merge ({}, defaultIntegrationConfig, {
        name: "Block Product Suggestions",
        base: {
          shop: {
            productSuggestionsLocales: []
          },
          product: {
            colorIds: null
          }
        }
      }),

      integrationConfigs = [ defaultIntegrationConfig, blockProductSuggestions ];

export { integrationConfigs, defaultIntegrationConfig };

import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCaseState, hideQuickView } from "./cases/CaseSlice";
import Thumbnail from "./Thumbnail";
import Sizes from "./Sizes";
import AddToCartButton from "./AddToCartButton";
import { Swatches } from "./Swatches";
import Meta from "./Meta";
import { FitFinder } from "./FitFinder";
import { getCaseProducts } from "./cases/caseProductsSlice";

function QuickView(props) {
  const caseState = useSelector(getCaseState),
        dispatch = useDispatch(),
        productData = caseState.productData,
        caseProducts = useSelector(getCaseProducts);

  const onQVHide = () => {
    dispatch(hideQuickView());
  };

  const seeFullDetails = () => {
    props.enterPdp(productData);
  };
  const productId = productData.styleId + "" + caseProducts?.default?.selectedColor;
  const availability = 4/5;

  return (
    <Modal className='quickview' show={caseState.showQuickView} size='lg' onHide={onQVHide}>
      <Modal.Header closeButton >
        <Modal.Title>{productData.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container className='quickview-product'>
          <Row>
            <Thumbnail src = {productData.thumbnail} />
            <Col>
              <Meta {... productData}/>
              <p>{productData.description}</p>
              <Swatches colors={productData.colorIds} />
              <Sizes sizes={productData.sizes}
                availability={4/5}
              />
              {/* <SizeChartButton /> */}
              <FitFinder externalId={productId} sizes={productData.sizes} sizeAvailability={availability}/>
              <AddToCartButton />
            </Col>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={seeFullDetails}>See in full details</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default QuickView;

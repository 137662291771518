import merge from "lodash.merge";

const experiments = {
  'ffplp_ab': {
    effects: {
      'fita': null,
      'control': {
        fitFinderOnPLP: null
      }
    }
  }
};

const defaultIntegrationConfig = {
        name: 'Default',
        base: {
          debug: true,
          globalEvents: {
            'fit_finder_open': () => document.querySelector('body .quickview')?.setAttribute('tabindex', ''),
            'fit_finder_close': () => document.querySelector('body .quickview')?.setAttribute('tabindex', '-1')
          },
          shop: {
            prefix: 'boss',
            node: '.testCase',
            country: {
              selector: 'meta[name="country"]',
              attr: 'content'
            },
            language: {
              selector: 'meta[name="language"]',
              attr: 'content'
            },
            userId: {
              selector: 'meta[name="user-id"]',
              attr: 'content'
            }
          },
          product: {
            container: '.quickview .row',

            hasManufacturedSizes: true,

            colorSeparator: '',
            styleId: {
              selector: 'meta[name="style-id"]',
              attr: 'content'
            },

            currentColorId: {
              selector: '.colors .colorId.selected',
              attr: 'value'
            },

            colorIds: {
              selector: '.colors .colorId',
              attr: 'value'
            },

            thumbnail: {
              selector: '.img-thumbnail',
              attr: 'src'
            },

            addToCart: 'button.add-to-cart',

            sizes: {
              items: '.sizes button',
              code: {
                selector: '.',
                prop: 'value'
              },
              isAvailable: {
                selector: '.',
                is: ':not([disabled])'
              },
              isSelected: {
                selector: '.',
                is: '.selected'
              },
              shouldSelectRecommended: true
            }
          },

          button: {
            anchor: '.sizes',
            anchorPlacement: 'after'
          },

          sizeChartButton: {
            elem: '.size-chart',
            shouldHide: true
          },
          fitFinderOnPLP: {
            button: {
              anchor: '.fitfinder-plp-button',
              anchorPlacement: 'append'
            }
          }
        },
        variants: [
          {
            key: 'quickview',
            variantType: 'quickview'
          },
          {
            key: 'mainPDP',
            variantType: 'primary',
            product: {
              container: '.product.row'
            },

            sizeChartButton: {
              elem: '.size-chart-button',
              shouldHide: true
            }
          }
        ]
      },

      blockedLocalesConfig = merge({}, defaultIntegrationConfig, {
        name: 'blockedLocalesConfig',
        base: {
          shop: {
            blockedLocales: ['de_DE']
          }
        }
      }),

      customButtonConfig = merge({}, defaultIntegrationConfig, {
        name: 'customButtonConfig',
        base: {
          fitFinderOnPLP: {
            button: {
              text: {
                de: {
                  informal: { // for the sake of testing the config; the copies are actually formal
                    default: 'Finden Sie Ihre beste Passform',
                    active: 'Ihr Größenprofil'
                  }
                }
              },
              style: {
                'button': {
                  'float': 'right'
                },
                'text': {
                  'margin': '0.1em',
                  'margin-left': '0px',
                  'font-size': '14px',
                  'color': '#5a5649',
                  'line-height': '23px',
                  'cursor': 'pointer'
                },
                'image': {
                  'width': '100px',
                  'height': '25px',
                  'cursor': 'pointer',
                  'display': 'inline-block'
                },
                'ariaCSS': {
                  'outline': '2px #000 solid',
                  'outline-offset': '3px'
                }
              },
              imageSrc: '//integrations.fitanalytics.com/assets/button/fit-finder-PDP-black.svg'
            }
          }
        }
      }),

      withGender = merge({}, defaultIntegrationConfig, {
        name: 'With Gender',
        base: {
          shop: {
            gender: {
              selector: 'meta[name="gender"]',
              attr: 'content'
            }
          }
        }
      }),
      withCategory = merge({}, defaultIntegrationConfig, {
        name: 'With Category',
        base: {
          shop: {
            category: {
              selector: 'meta[name="category"]',
              attr: 'content'
            }
          }
        }
      }),
      withGenderAndCategory = merge({}, defaultIntegrationConfig, {
        name: 'With Gender and Category',
        base: {
          shop: {
            gender: {
              selector: 'meta[name="gender"]',
              attr: 'content'
            },
            category: {
              selector: 'meta[name="category"]',
              attr: 'content'
            }
          }
        }
      }),
      ffplpInControl = merge({}, defaultIntegrationConfig, {
        name: 'FFPLP in control funnel',
        base: {
          mockExperiments: {
            'ffplp_ab': 'control'
          },
          experiments: experiments
        }
      }),
      ffplpInFitA = merge({}, defaultIntegrationConfig, {
        name: 'FFPLP in fita funnel',
        base: {
          mockExperiments: {
            'ffplp_ab': 'fita'
          },
          experiments: experiments
        }
      }),

      integrationConfigs = [
        defaultIntegrationConfig,
        blockedLocalesConfig,
        customButtonConfig,
        withGender,
        withCategory,
        withGenderAndCategory,
        ffplpInControl,
        ffplpInFitA
      ];

export { integrationConfigs, defaultIntegrationConfig };

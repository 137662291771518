import merge from "lodash.merge";

var defaultIntegrationConfig = {
  name: 'Default',
  addOns: {
    msa: {
      sizes: '.sizes button',
      sizeCode: {
        selector: '.',
        prop: 'value'
      },
      cart: {
        source: () => window.fitAnalyticsData.operations.getCartItems(),
        serial: {
          path: 'productId'
        },
        size: {
          path: 'size'
        }
      }
    }
  },
  base: {
    debug: true,
    addOns: () => window._fitAnalytics.addOns,
    shop: {
      prefix: 'boss',
      country: {
        selector: 'meta[name="country"]',
        attr: 'content'
      },
      language: {
        selector: 'meta[name="language"]',
        attr: 'content'
      },
      userLanguage: {
        selector: 'meta[name="user-language"]',
        attr: 'content'
      },
      userId: {
        selector: 'meta[name="user-id"]',
        attr: 'content'
      },
      blockedLocales: ['fi', 'ru', 'xx'],
      prefetch: false
    },

    scope: '.testCase',
    product: {
      container: '.product',

      hasManufacturedSizes: true,

      currentSerial: {
        selector: 'meta[name="product-id"]',
        attr: 'content'
      },

      thumbnail: {
        selector: '.img-thumbnail',
        attr: 'src'
      },

      addToCart: 'button.add-to-cart',

      sizes: {
        items: '.sizes button',
        code: {
          selector: '.',
          prop: 'value'
        },
        isAvailable: {
          selector: '.',
          is: ':not([disabled])'
        },
        isSelected: {
          selector: '.',
          is: '.selected'
        },
        shouldSelectRecommended: true
      }
    },

    button: {
      anchor: '.sizes',
      anchorPlacement: 'after'
    },

    sizeChartButton: {
      elem: '.size-chart-button',
      shouldHide: true
    }
  }
};

const deLanguage = merge({}, defaultIntegrationConfig, {
  name: 'DE language',
  base: {
    shop: {
      userLanguage: () => 'de'
    }
  }
});

var integrationConfigs = [
  defaultIntegrationConfig,
  deLanguage
];

export {defaultIntegrationConfig, integrationConfigs};

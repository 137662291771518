import { createSlice } from "@reduxjs/toolkit";
import testCases from './cases';

const initialState = [...testCases];

const testCasesSlice = createSlice({
  name: 'testCases',
  initialState,
  reducers: {
  }
});

export const getCases = (state) => state.testCases;
export default testCasesSlice.reducer;

import { Button, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setProductData, showQuickView } from "./cases/CaseSlice";

function ProductTile({enterPdp = ()=>{}, ... props}) {
  const dispatch = useDispatch();
  const onTileClick = (e) => {
    e.stopPropagation();
    dispatch(setProductData(props));
    dispatch(showQuickView());
  };
  let qvButtonStyle = {
    cursor: 'pointer',
    margin: '0 auto 10px',
    display: 'flex'
  };

  const onCardClick = () => {
    enterPdp(props);
  };

  return (
    <Card className='product-tile' onClick={onCardClick} style={{cursor: 'pointer'}}>
      <Card.Img variant='top' src={props.thumbnail}/>
      <Card.Body>
        <Button
          className='quickview-button'
          variant='dark'
          style={qvButtonStyle}
          onClick={onTileClick}
          hidden={props.noQuickview}> QuickView</Button>
        <Card.Title as='h6'>{props.title}</Card.Title>
        <Card.Text>{props.description}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default ProductTile;

import { Col, Container, Form, Row } from "react-bootstrap";
import { useSelector, useDispatch} from "react-redux";
import { getPageConfig, toggleDefaultIntegration, toggleHighlight, toggleShouldMountIntegrationByDefault } from "./PageConfigSlice";

function PageConfig() {
  const pageConfig = useSelector(getPageConfig);
  const dispatch = useDispatch();
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Form>
              <Form.Check
                type="switch"
                id="defaultIntegration"
                label="Use default Integration"
                checked={pageConfig.isDefaultIntegration}
                onChange={()=>dispatch(toggleDefaultIntegration())}/>
              <Form.Check
                type="switch"
                id="integrationHighlight"
                label="Use integration highlight"
                checked={pageConfig.isHighlightActive}
                onChange={()=>dispatch(toggleHighlight())}/>
              <Form.Check
                type="switch"
                id="shouldMountIntegrationByDefault"
                label="Use integration.start() by default"
                checked={pageConfig.shouldMountIntegrationByDefault}
                onChange={()=>dispatch(toggleShouldMountIntegrationByDefault())} />
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PageConfig;

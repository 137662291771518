import { useState, useEffect } from 'react';

export const useShopSessionId = (caseId='global') => {
  const [sessionId, setSessionId] = useState(`ssid-${caseId}`);

  useEffect(() => {
    document.cookie = `ssid=${sessionId}; path=/`;
  }, []);

  return sessionId;
};

import { useState } from 'react';
import {Navbar, Container, Nav, NavDropdown} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

function TestingHeader() {
  const navigate = useNavigate();

  function goHome() {
    navigate('./');
  }

  function goAlioop() {
    navigate('/');
  }

  function gotoPageConfig() {
    navigate('./page-config');
  }

  return (
    <>
      <Navbar bg='light' expand="lg" sticky='top'>
        <Container>
          <Navbar.Brand onClick= {goAlioop} style={{cursor: 'pointer'}}>Alioop</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick= {goHome}>Home</Nav.Link>
              <Nav.Link onClick = {gotoPageConfig}>Page Config</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default TestingHeader;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import { getPageConfig } from "../PageConfigSlice";
import { getCaseState } from "./CaseSlice";
import AddToCartButton from "../AddToCartButton";
import SizeChart from "../SizeChart";
import Sizes from "../Sizes";
import Thumbnail from "../Thumbnail";
import Title from "../Title";
import ConfigPane from "./ConfigPane";
import Meta from "../Meta";
import Cookies from "js-cookie";
import { integrationConfigs, defaultIntegrationConfig } from "../../../integration_configs/config13";
import { useIntegration } from "./useIntegration";

function Case13(props = {}) {
  const pageConfig = useSelector(getPageConfig),
        caseState = useSelector(getCaseState),
        [isAdoptionFlagEnabled, setFlagState] = useState(Cookies.get('fita.adoptionFlagDisabled') !== '1');

  useIntegration(defaultIntegrationConfig);

  const unsetCookie = () => {
    Cookies.set('fita.adoptionFlagDisabled', '', {path: '/'});
    setFlagState(true);
  };

  return (<>
    <ConfigPane configs={integrationConfigs}/>
    <Container className='testCase mt-4'>
      <Row className='mb-3' hidden={isAdoptionFlagEnabled}>
        <Col>
          <Button onClick={unsetCookie}>Unset the Adoption Flag cookie</Button>
          <p className='mt-2' hidden={!pageConfig.isDefaultIntegration && !caseState.isIntegrationLaunched}>
            After unsetting the cookie, manually reload the page.
          </p>
        </Col>
      </Row>
      <Row className="product">
        <Thumbnail src = {props.thumbnail}/>
        <Col>
          <Meta {... props}/>
          <Title title={props.title}/>
          <p>{props.description}</p>
          <Sizes sizes={props.sizes}
            availability={4/5}
          />
          <SizeChart />
          <AddToCartButton />
        </Col>
      </Row>
    </Container>
  </>);
}

export { Case13 };

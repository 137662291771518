import { Button, Offcanvas, Form } from "react-bootstrap";
import CartItem from "./CartItem";
import { getCartItems, emptyCart, getShow, setShow, getAsync, setAsync } from "./cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

function Cart({ name, ...props }) {
  const cartItems = useSelector(getCartItems),
        show = useSelector(getShow),
        isAsync = useSelector(getAsync),
        dispatch = useDispatch();

  const handleClose = () => dispatch(setShow(false));
  const handleShow = () => dispatch(setShow(true));

  window.cartItems = cartItems.map(item => {
    return {
      'productId': item.productId,
      'size': item.size
    };
  });

  useEffect(() => {
    return () => {
      dispatch(emptyCart());
    };
  }, []);

  return (
    <>
      <style type="text/css">
        {`
          .offcanvas-body .btn-close {
            float: right;
          }
          .offcanvas-body .card {
            margin-bottom: 10px;
          }
        `}
      </style>
      <Button variant="primary" onClick={handleShow} className="me-2">Cart</Button>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Form>
            <Form.Check
              type="switch"
              id="asyncCart"
              label="Async"
              checked={isAsync}
              onChange={() => dispatch(setAsync(!isAsync))}/>
          </Form>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {cartItems.length > 0 ? cartItems.map((cartItem, idx) => <CartItem key={idx} cartItem={cartItem}/>) : <strong>Cart empty</strong>}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Cart;
